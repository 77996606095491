import document from './document.svg';
import video from './video.svg';
import arrow from './arrow.svg';
import heart from './heart.svg';
import Reset from './Reset.svg';
import Summary from './Summary.svg';

const misc = {
  document,
  video,
  arrow,
  heart,
  Reset,
  Summary,
};

export default misc;
