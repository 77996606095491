import styled from 'styled-components';
import { marble as marbleImg } from '../assets';

export const DesktopWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  min-height: calc(${(props) => props.theme.playerSizes.tabletP.height} + 30px);

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-image: url(${marbleImg});
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  & > div {
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 50px;
    width: 90vw;
    max-width: 1200px;
    height: max-content;
    margin: 0 auto;
  }
`;

export const MobileWrapper = styled.div`
  height: ${(props) => props.theme.mobile.height};
  width: 100vw;
  overflow: hidden;

  background-image: url(${marbleImg});
  background-repeat: no-repeat;
  background-size: cover;

  display: grid;
  grid-template-rows: auto max-content;
`;
