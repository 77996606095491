import { createSlice } from '@reduxjs/toolkit';
import { descriptions, skinTones } from '../data';

export const LANDING = {
  default: 'default',
  resume: 'resume',
};

export const MODALS = {
  share: 'share',
};

const initialState = {
  section: 0,
  landing: LANDING.default,
  shankCategory: null,
  showDiamondIntro: true,
  selectedThumbnail: 0,
  selectedHandColor: skinTones.Light,
  configurationSaved: false,
  modal: undefined,
};

const { actions, reducer } = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    clearState: (state, action) => {
      state.section = 0;
      state.shankCategory = null;
      state.selectedThumbnail = 0;
    },
    setSection: (state, action) => {
      state.section = action.payload;
    },
    setShankCategory: (state, action) => {
      state.shankCategory = action.payload;
    },
    setShowDiamondIntro: (state, action) => {
      state.showDiamondIntro = action.payload || !state.showDiamondIntro;
    },
    setSelectedThumbnail: (state, action) => {
      state.selectedThumbnail = action.payload;
    },
    setSelectedHandColor: (state, action) => {
      state.selectedHandColor = action.payload;
    },
    setLanding: (state, action) => {
      state.landing = action.payload || undefined;
    },
    setModal: (state, action) => {
      state.modal = action.payload || undefined;
    },
    restoreConfiguration: (state, action) => {
      state.section = 4;
      state.landing = undefined;
      if (action.payload) state.showDiamondIntro = false;
    },
    setConfigurationSaved: (state, action) => {
      state.configurationSaved = action.payload;
    },
  },
});

export const {
  clearState,
  setSection,
  setShowDiamondIntro,
  setShankCategory,
  setSelectedThumbnail,
  setSelectedHandColor,
  setLanding,
  setModal,
  restoreConfiguration,
  setConfigurationSaved,
} = actions;

export const getSection = (state) => state.ui.section;

export const getShankCategory = (state) => state.ui.shankCategory || '';

export const getShowDiamondIntro = (state) => state.ui.showDiamondIntro;

export const getDescription = (selection) => {
  const [key, val] = Object.entries(selection)[0];
  return descriptions?.[key]?.[val] || '';
};

export const getSelectedThumbnail = (state) => state.ui.selectedThumbnail;

export const getSelectedHandColor = (state) => state.ui.selectedHandColor;

export const getLanding = (state) => state.ui.landing;

export const getModal = (state) => state.ui.modal;

export const getConfigurationSaved = (state) => state.ui.configurationSaved;

export default reducer;
