export const deviceSizes = {
  mobileS: '350px',
  mobile: '599px',
  tabletP: '600px',
  tabletL: '900px',
  desktop: '1200px',
};

export const MEDIA_QUERIES = {
  mobileS: `(max-width: ${deviceSizes.mobileS})`,
  mobile: `(max-width: ${deviceSizes.mobile})`,
  tabletP: `(min-width: ${deviceSizes.tabletP})`,
  tabletL: `(min-width: ${deviceSizes.tabletL})`,
  desktop: `(min-width: ${deviceSizes.desktop})`,
};

const theme = {
  mediaQueries: MEDIA_QUERIES,
  mobile: {
    height:
      process.env.NODE_ENV === 'development'
        ? window.innerHeight + 'px'
        : `calc(${window.innerHeight}px - 62px)`,
  },
  handColors: {
    light: '#C8AE9D',
    medium: '#83533C',
    dark: '#431C14',
  },
  colors: {
    gold: '#D7A95A',
  },
  playerSizes: {
    tabletP: {
      height: '650px',
      width: '520px',
    },
    mobileS: {
      height: `calc(${window.innerHeight}px - (62px + 340px))`,
    },
    mobile: {
      height: `calc(${window.innerHeight}px - (62px + 360px))`,
    },
  },
  configurator: {
    mobile: { height: `360px` },
    mobileS: { height: `340px` },
  },
  thumbnailSizes: {
    mobile: '36px',
    tabletP: '70px',
  },
  menuIconSizes: {
    mobile: '38px',
    tabletP: '45px',
  },
  radioButtonIconSizes: {
    mobileS: '32px',
    mobile: '38px',
    tabletP: '60px',
  },
  radioButtonWidth: {
    mobile: '90px',
    tabletP: '100px',
  },
  togglePosition: {
    tabletP: {
      bottom: '25px',
      left: '25px',
    },
  },
  resetButton: {
    mobile: {
      top: '15px',
      left: '25px',
      height: '38px',
      width: '38px',
    },
  },
};

export { theme };

export default theme;
