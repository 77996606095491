import styled from 'styled-components';

export const DesktopWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;

  & > div:nth-child(1) {
    color: black;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: GothamLight;
    font-size: 15px;
  }

  & > div:nth-child(2) {
    font-family: BruneiDarussalam;
    line-height: 62px;
    font-size: 60px;
    color: black;
  }

  hr {
    border: none;
    outline: none;
    height: 1px;
    background: ${(props) => props.theme.colors.gold};
    margin: 0px auto;
    margin-top: 20px;
  }
`;

export const MobileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-gap: 10px;
  margin-bottom: 12px;

  @media ${(props) => props.theme.mediaQueries.mobileS} {
    margin-bottom: 8px;
  }

  & > div:nth-child(1),
  & > div:nth-child(3) {
    height: max-content;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  & > div:nth-child(2) {
    font-family: BruneiDarussalam;
    line-height: 43px;
    font-size: 32px;
    color: black;
  }

  hr {
    height: 1px;
    border: none;
    outline: none;
    margin: 0;
    background: ${(props) => props.theme.colors.gold};
  }
`;
