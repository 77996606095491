import React from 'react';
import {
  getProductData,
  setDiamond,
  getGems,
  setDiamondSize,
} from '../../store/threekit';
import { useDispatch, useSelector } from 'react-redux';
import { RowHeading, RowWrapper, Scrollable } from './table.styles';
import icons from '../../assets/icons';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERIES } from '../../theme';
import { diamondSizes } from '../../data';

const { document: documentIcon, video: videoIcon } = icons;

const Row = ({
  weight,
  color,
  clarity,
  price,
  selected,
  handleClick,
  onClickDocument,
  onClickVideo,
}) => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.mobile });

  const handleClickVideo = (e) => {
    if (isMobile && !selected) return;
    onClickVideo();
    e.stopPropagation();
  };

  const handleClickDocument = (e) => {
    if (isMobile && !selected) return;
    onClickDocument();
    e.stopPropagation();
  };

  return (
    <RowWrapper onClick={handleClick} selected={selected}>
      <div>{weight}</div>
      <div>{color}</div>
      <div>{clarity}</div>
      <div>${price}</div>
      <div>
        {!!handleClickDocument ? (
          <img
            onClick={handleClickDocument}
            style={{ marginRight: '8px' }}
            src={documentIcon}
            alt="pdf"
          />
        ) : null}
        <img onClick={handleClickVideo} src={videoIcon} alt="video" />
      </div>
    </RowWrapper>
  );
};

const Table = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.mobile });
  const gems = useSelector(getGems);
  const { head, shank, diamond } = useSelector(getProductData);

  const handleClickLink = (sku, url) => {
    if (!sku || !url) return;
    dispatch(setDiamond(sku));
    if (!url) return;
    window.open(url);
  };

  const handleSelectDiamond = (sku, weightStr) => {
    const diamondSize =
      parseFloat(weightStr) >= 1.5 ? diamondSizes['2ct'] : diamondSizes['1ct'];
    dispatch(setDiamondSize(diamondSize));
    if (sku === diamond?.sku) return dispatch(setDiamond());
    dispatch(setDiamond(sku));
  };

  return [
    <RowHeading key="table-heading">
      <div>Carat</div>
      <div>Color</div>
      <div>Clarity</div>
      <div>{isMobile ? 'Price' : 'Total Price'}</div>
      <div />
    </RowHeading>,
    <Scrollable key="table-content">
      {gems.map((el, i) => {
        const gemCt = `${Math.round(parseFloat(el['Weight']))}ct`;
        const headPrice = head[gemCt]?.price || 0;
        const shankPrice = shank[gemCt]?.price || 0;

        return (
          <Row
            key={i}
            sku={el['SKU']}
            weight={el['Weight']}
            color={el['Col']}
            clarity={el['Clarity']}
            selected={el['SKU'] === diamond?.sku}
            price={(el['Price'] + headPrice + shankPrice)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            handleClick={() => handleSelectDiamond(el['SKU'], el['Weight'])}
            onClickVideo={() => handleClickLink(el['SKU'], el['diamond_video'])}
            onClickDocument={
              el['Cert Url']?.length
                ? () => handleClickLink(el['SKU'], el['Cert Url'])
                : undefined
            }
          />
        );
      })}
    </Scrollable>,
  ];
};

export default Table;
