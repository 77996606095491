import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
  text-align: center;

  @media ${props => props.theme.mediaQueries.mobile} {
    & > div > div {
      opacity: ${props => (props.hide ? '0' : '1')};
      transition: all 0.2s;
    }
    & > div > div:last-child {
      opacity: 1;
    }
  }

  @media ${props => props.theme.mediaQueries.tabletP} {
    opacity: ${props => (props.hide ? '0' : '1')};
    transition: all 0.2s;
    height: ${props =>
      props.hide ? '0px' : props.hasChildren ? '372px' : '150px'};
  }

  & > div:nth-child(1) {
    @media ${props => props.theme.mediaQueries.mobile} {
      padding: 10px 12px;
      padding-bottom: 15px;
      height: 100%;
      display: grid;
      grid-template-rows: ${props =>
        props.hasChildren
          ? `max-content auto max-content`
          : 'auto max-content'};
    }

    @media ${props => props.theme.mediaQueries.tabletP} {
      padding-top: 10px;
      height: 100%;
      display: grid;
      grid-template-rows: ${props =>
        props.hasChildren
          ? `max-content auto max-content`
          : 'auto max-content'};
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div:nth-child(1) {
    @media ${props => props.theme.mediaQueries.tabletP} {
      visibility: hidden;
    }
  }
`;

export const Button = styled.div`
  width: max-content;
  height: 40px;
  float: right;
  font-family: BruneiDarussalam;
  font-size: 54px;
  cursor: pointer;
  color: black;
  transition: all 0.3s;

  visibility: ${props => (props.hide ? 'hidden' : 'visibile')};

  & > div {
    @media ${props => props.theme.mediaQueries.mobile} {
      font-size: 20px;
      font-family: GothamLight;
    }

    @media ${props => props.theme.mediaQueries.tabletP} {
      font-family: BruneiDarussalam;
    }
  }

  &:hover {
    color: ${props => props.theme.colors.gold};
  }

  & > div {
    height: max-content;
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
`;

export const HeadingWrapper = styled.div`
  @media ${props => props.theme.mediaQueries.mobile} {
    height: max-content;
    ${props =>
      props.center
        ? `
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      `
        : ''}
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-gap: 20px;
  margin-bottom: 10px;

  opacity: ${props => (props.show ? 1 : 0)};

  & > div:nth-child(1),
  & > div:nth-child(3) {
    height: max-content;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  & > div:nth-child(2) {
    text-transform: capitalize;
    font-family: GothamLight;
    font-size: 17px;
    color: black;
    text-transform: uppercase;
  }

  hr {
    height: 1px;
    border: none;
    outline: none;
    margin: 0;
    background: ${props => props.theme.colors.gold};
  }
`;

export const Description = styled.div`
  font-family: 'EB Garamond', serif;

  @media ${props => props.theme.mediaQueries.mobile} {
    font-size: 13px;
  }
`;
