import React from 'react';
import { MobileWrapper } from './app.styles';

import Modal from '../components/Modal';
import Landing from '../components/Landing';
import Configurator from '../components/Configurator';
import Player from '../components/Player';

const MobileApp = () => {
  return (
    <>
      <MobileWrapper>
        <Player />
        <Configurator />
      </MobileWrapper>
      <Landing />
      <Modal />
    </>
  );
};

export default MobileApp;
