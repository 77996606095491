import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRenderUrls } from '../../store/threekit';
import {
  setSelectedThumbnail,
  getSelectedThumbnail,
  setSelectedHandColor,
  getSelectedHandColor,
} from '../../store/ui';
import { ThumbnailsWrapper, Thumbnail, ColorSelector } from './player.styles';
import { skinTones } from '../../data';

const Thumbnails = () => {
  const dispatch = useDispatch();
  const thumbnailUrls = useSelector(getRenderUrls);
  const selectedThumbnail = useSelector(getSelectedThumbnail);
  const handColor = useSelector(getSelectedHandColor);

  const handleClickThumbnail = val => dispatch(setSelectedThumbnail(val));
  const handleClickHandColor = val => dispatch(setSelectedHandColor(val));

  if (!thumbnailUrls) return null;

  return (
    <ThumbnailsWrapper>
      <ColorSelector show={1 === selectedThumbnail}>
        <div onClick={() => handleClickHandColor(skinTones.Light)} />
        <div onClick={() => handleClickHandColor(skinTones.Medium)} />
        <div onClick={() => handleClickHandColor(skinTones.Dark)} />
      </ColorSelector>
      <div>
        <Thumbnail
          selected={0 === selectedThumbnail}
          onClick={() => handleClickThumbnail(0)}
        >
          <div>
            <img src={thumbnailUrls?.vray.ringOnly} alt="select-vray" />
          </div>
        </Thumbnail>
        <Thumbnail
          selected={1 === selectedThumbnail}
          onClick={() => handleClickThumbnail(1)}
        >
          <div>
            <img src={thumbnailUrls?.vray.hands[handColor]} alt="select-hand" />
          </div>
        </Thumbnail>
        <Thumbnail
          selected={2 === selectedThumbnail}
          onClick={() => handleClickThumbnail(2)}
        >
          <div>
            <img src={thumbnailUrls?.webgl.Front} alt="select-webgl" />
          </div>
        </Thumbnail>
      </div>
    </ThumbnailsWrapper>
  );
};

export default Thumbnails;
