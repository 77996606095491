import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSection,
  setSection,
  clearState as clearUiState,
  getLanding,
} from '../../store/ui';
import {
  getConfiguration,
  setCamera,
  getSectionIcons,
  clearState as clearThreekitState,
} from '../../store/threekit';
import { Head, Metal, Shank, Shape, Summary } from '../../components/Sections';
import { icons } from '../../assets';
import { CAMERA_OPTIONS } from '../../constants';
import {
  Wrapper,
  SectionSelectorWrapper,
  MenuItemWrapper,
  ResetWrapper,
} from './configurator.styles';

const configurator = [
  {
    attributeLabel: 'Shape',
    camera: CAMERA_OPTIONS.shape,
    component: <Shape />,
    dependency: [],
  },
  {
    attributeLabel: 'Head',
    camera: CAMERA_OPTIONS.head,
    component: <Head />,
    dependency: ['Shape'],
  },
  {
    attributeLabel: 'Shank',
    camera: CAMERA_OPTIONS.shank,
    component: <Shank />,
    dependency: ['Shape', 'Head'],
  },
  {
    attributeLabel: 'Metal',
    camera: CAMERA_OPTIONS.metal,
    component: <Metal />,
    dependency: ['Shape', 'Head', 'Shank'],
  },
  {
    attributeLabel: 'Summary',
    camera: null,
    component: <Summary />,
    dependency: ['Shape', 'Head', 'Shank', 'Metal'],
  },
];

const MenuItems = ({
  sectionIcons,
  options,
  selected: selectedSection,
  onClick: handleClick,
}) => {
  const configuration = useSelector(getConfiguration);

  return options.map(({ dependency }, i) => {
    const isDisabled = dependency.reduce((result, dependency) => {
      if (result || !configuration) return result;
      if (!configuration[dependency] || !configuration[dependency].length)
        result = true;
      return result;
    }, false);

    return (
      <MenuItemWrapper
        key={i}
        selected={i === selectedSection}
        disabled={isDisabled}
        onClick={() => !isDisabled && handleClick(i)}
      >
        <div>
          <img src={icons[sectionIcons[i]]} alt={i} />
        </div>
      </MenuItemWrapper>
    );
  });
};

const Configurator = () => {
  const dispatch = useDispatch();
  const section = useSelector(getSection);
  const sectionIcons = useSelector(getSectionIcons);
  const landing = useSelector(getLanding);

  const handleSetSection = (idx) => {
    if (configurator[idx].camera) setCamera(configurator[idx].camera);
    dispatch(setSection(idx));
  };

  const handleClickReset = () => {
    dispatch(clearUiState());
    dispatch(clearThreekitState());
  };

  return (
    <Wrapper hide={!!landing}>
      <div>
        <ResetWrapper onClick={handleClickReset}>
          <div>
            <img src={icons.Reset} alt="reset" />
          </div>
        </ResetWrapper>
        <MenuItems
          sectionIcons={sectionIcons}
          options={configurator}
          selected={section}
          onClick={handleSetSection}
        />
      </div>
      <div>
        {typeof section === 'number' && configurator[section].component}
      </div>
    </Wrapper>
  );
};

export const SectionSelector = () => {
  const dispatch = useDispatch();
  const section = useSelector(getSection);
  const sectionIcons = useSelector(getSectionIcons);

  const handleSetSection = (val) => dispatch(setSection(val));

  return (
    <SectionSelectorWrapper>
      <MenuItems
        sectionIcons={sectionIcons}
        options={configurator}
        selected={section}
        onClick={handleSetSection}
      />
    </SectionSelectorWrapper>
  );
};

export default Configurator;
