import yellowGold from './Yellow-Gold.svg';
import roseGold from './Rose-Gold.svg';
import Platinum from './Platinum.svg';

const metals = {
  '18kYellow': yellowGold,
  '18kRose': roseGold,
  Platinum,
};

export default metals;
