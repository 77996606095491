import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DesktopWrapper,
  ToggleWrapper,
  ResetWrapper,
  ImageWrapper,
  MobileWrapper,
  MobileActions,
  HeartWrapper,
  ShareWrapper,
  MobileActionArea,
} from './player.styles';
import {
  initializePlayer,
  getRenderUrls,
  clearState as clearThreekitState,
  getProductData,
  getConfigurationAsQueryParams,
  createThreekitConfiguration,
} from '../../store/threekit';
import {
  getSection,
  clearState as clearUiState,
  getSelectedThumbnail,
  getSelectedHandColor,
  getLanding,
  getConfigurationSaved,
  setConfigurationSaved,
  setModal,
  MODALS,
} from '../../store/ui';
import Toggle from '../Toggle';
import Thumbnails from './Thumbnails';
import { icons } from '../../assets';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERIES } from '../../theme';
import { message } from 'antd';
import { LOGIN_REDIRECT_URL } from '../../constants';
import HeartIcon from '../HeartIcon';
import { MailOutlined } from '@ant-design/icons';

const Player = () => {
  const dispatch = useDispatch();
  const section = useSelector(getSection);
  const landing = useSelector(getLanding);
  const selectedThumbnail = useSelector(getSelectedThumbnail);
  const handColor = useSelector(getSelectedHandColor);
  const thumbnailUrls = useSelector(getRenderUrls);
  const { head, shank, diamond } = useSelector(getProductData);
  const configurationSaved = useSelector(getConfigurationSaved);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.mobile });

  const handleClickReset = () => {
    dispatch(clearUiState());
    dispatch(clearThreekitState());
  };

  const handleClickSave = async () => {
    if (configurationSaved)
      return message.success({
        icon: <HeartIcon />,
        content: 'This ring has been saved to your portfolio',
      });
    if (!window.customer_email) {
      const configAsParams = getConfigurationAsQueryParams({ login: 'yes' });
      window.location.href = LOGIN_REDIRECT_URL + configAsParams;
    } else {
      await createThreekitConfiguration(head, shank, diamond);
      dispatch(setConfigurationSaved(true));
      message.success({
        icon: <HeartIcon />,
        content: 'This ring has been saved to your portfolio',
      });
    }
  };

  const handleClickShare = () => dispatch(setModal(MODALS.share));

  useEffect(() => {
    (() => {
      dispatch(initializePlayer({ isMobile }));
    })();
    // eslint-disable-next-line
  }, []);

  let imgUrl;

  if (section >= 4) {
    if (selectedThumbnail === 0) {
      imgUrl = thumbnailUrls?.vray.ringOnly;
    }
    if (selectedThumbnail === 1) {
      imgUrl = thumbnailUrls?.vray.hands[handColor];
    }
  }

  if (isMobile)
    return (
      <MobileWrapper showPlayer={!!!imgUrl}>
        <MobileActions>
          <ResetWrapper hide={section >= 4} onClick={handleClickReset}>
            <div>
              <img src={icons.Reset} alt="reset" />
            </div>
          </ResetWrapper>
          {section < 4 ? (
            <Toggle />
          ) : (
            <MobileActionArea>
              <Thumbnails />
              <ShareWrapper onClick={handleClickShare}>
                <MailOutlined />
              </ShareWrapper>
            </MobileActionArea>
          )}
        </MobileActions>
        <div>
          <div id="player-root" />
          {!!imgUrl ? (
            <ImageWrapper>
              <img src={imgUrl} alt="ring" />
            </ImageWrapper>
          ) : null}
        </div>
      </MobileWrapper>
    );

  return (
    <DesktopWrapper hide={!!landing} showPlayer={!!!imgUrl}>
      <div id="player-root" />
      {!!imgUrl ? (
        <ImageWrapper>
          <img src={imgUrl} alt="ring" />
        </ImageWrapper>
      ) : null}
      <ResetWrapper onClick={handleClickReset}>
        <div>
          <img src={icons.Reset} alt="reset" />
        </div>
      </ResetWrapper>
      <ToggleWrapper>
        <Toggle />
      </ToggleWrapper>
      {section >= 4 && (
        <ShareWrapper onClick={handleClickShare}>
          <MailOutlined />
        </ShareWrapper>
      )}
      {section >= 4 && <Thumbnails />}
      {section >= 4 && (
        <HeartWrapper disabled={configurationSaved} onClick={handleClickSave}>
          <img src={icons.heart} alt="save" />
        </HeartWrapper>
      )}
    </DesktopWrapper>
  );
};

export default Player;
