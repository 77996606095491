import styled from 'styled-components';
import { SectionWrapper } from '../SharedComponents/sharedStyles.styles';

export const Wrapper = styled(SectionWrapper)`
  grid-template-rows: max-content auto max-content;
`;

export const Tabs = styled.div`
  margin: 0 auto;
  text-align: center;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    height: 100%;
    max-height: 320px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px; */
    width: 90%;
  }
`;

export const Tab = styled.div`
  cursor: pointer;
  transition: all 0.3s;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    & > div:nth-child(2) {
      display: none;
    }
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    min-width: 105px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.gold};
  }

  ${(props) => props.disable && `opacity: 0.3;`}

  & > div:nth-child(1) {
    height: 34px;
    width: 34px;
    margin: 0 auto;
    margin-bottom: 8px;

    @media ${(props) => props.theme.mediaQueries.mobileS} {
      height: ${(props) => props.theme.radioButtonIconSizes.mobileS};
      width: ${(props) => props.theme.radioButtonIconSizes.mobileS};
  }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  & > div:nth-child(2) {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: GothamLight;
    font-size: ${(props) => (props.selected ? '15px' : '13px')};

    font-size: 12px;
  }
`;
