import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LANDING, getLanding, setLanding } from '../../store/ui';
import { Wrapper, Heading, Body, Footer } from './landing.styles';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export const DefaultLanding = () => {
  const dispatch = useDispatch();
  const landing = useSelector(getLanding);

  const handleClick = () => dispatch(setLanding(undefined));

  return (
    <Wrapper show={!!landing}>
      <div />
      <div>
        <Heading>Welcome to the studio</Heading>
        <Body>
          <p>
            I created my signature design elements to be timeless but also
            distinctive, and to reflect your story. In the studio you will see
            how many unique ways they can be combined and made your own. Create
            a portfolio of designs and when a favorite is chosen it will be made
            to fit your diamond and your hand right here in New York City by the
            finest jewelers in the world.
          </p>
          <p>
            And don’t worry, I’m here to guide you along the way. Grab a glass
            of champagne and let’s get started.
          </p>
        </Body>
        <Footer>
          <div>Lindsey</div>
          <div onClick={handleClick}>
            Create Your Ring
            <ArrowRightOutlined
              style={{ fontSize: '25px', marginLeft: '5px' }}
            />
          </div>
        </Footer>
      </div>
    </Wrapper>
  );
};

export const ResumeLanding = () => {
  const dispatch = useDispatch();
  const landing = useSelector(getLanding);

  const handleClick = () => dispatch(setLanding(false));

  return (
    <Wrapper show={!!landing}>
      <div />
      <div>
        <Heading>Welcome to the Studio</Heading>
        <Body>
          <div>
            Please wait while we load your design.
            <Spin
              style={{ marginLeft: '12px', marginTop: '5px' }}
              size="medium"
            />
          </div>
          {/* <p>
            And don’t worry, I’m here to guide you along the way. Grab a glass
            of champagne and let’s get started.
          </p> */}
        </Body>
        {/* <Footer>
          <div>Design a new ring</div>
          <div onClick={handleClick}>
            Return to your ring
            <ArrowRightOutlined
              style={{ fontSize: '25px', marginLeft: '5px' }}
            />
          </div>
        </Footer> */}
      </div>
    </Wrapper>
  );
};

const Landing = () => {
  const landing = useSelector(getLanding);
  return landing === LANDING.resume ? <ResumeLanding /> : <DefaultLanding />;
};

export default Landing;
