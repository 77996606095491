import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import uiReducer from './ui';
import threekitReducder from './threekit';

const store = configureStore({
  reducer: {
    ui: uiReducer,
    threekit: threekitReducder,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }).concat(logger),
});

export default store;
