import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;

  width: 100vw;

  background: rgba(0, 0, 0, 0.3);
  font-family: 'EB Garamond', serif;
  font-size: 16px;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    height: ${(props) => props.theme.mobile.height};
    max-height: 100vh;
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    height: 100vh;
    min-height: calc(
      ${(props) => props.theme.playerSizes.tabletP.height} + 30px
    );
  }

  & > div:nth-child(1) {
    /* height: ${(props) => (props.fullHeight ? 'max-content' : '500px')}; */
    height: max-content;
    width: 320px;
    width: 500px;

    @media ${(props) => props.theme.mediaQueries.mobile} {
      width: 300px;
    }

    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;

    background: white;
    padding: 15px 20px;
    border-radius: 4px;
  }
`;

export const Heading = styled.div`
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  font-family: GothamLight;

  margin-bottom: 25px;
`;

export const Button = styled.div`
  width: max-content;
  margin: 0 auto;

  cursor: pointer;

  margin-top: 20px;
  font-family: BruneiDarussalam;
  font-size: 40px;

  &:hover {
    color: #d7a95a;
  }
`;

export const SendEmailButton = styled.div`
  width: max-content;
  padding: 5px 12px;
  border: 1px solid grey;
  float: right;
  margin-top: 12px;
  margin-bottom: 22px;
  cursor: pointer;
  font-family: GothamLight;
  font-size: 13px;
  border-radius: 3px;

  &:hover {
    border-color: #d7a95a;
    color: #d7a95a;
  }
`;

export const Toggle = styled.div`
  width: max-content;
  margin: 10px auto 15px;
  display: flex;
  font-family: GothamLight;
  font-size: 13px;

  & > div {
    width: max-content;
    padding: 5px 12px;
    border: 1px solid grey;
    cursor: pointer;

    &:hover {
      color: #d7a95a;
    }
  }

  & > div:nth-child(1) {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    ${(props) =>
      props.selected
        ? 'border-color: #d7a95a; color: #d7a95a;'
        : 'border-right: 0px;'}
  }

  & > div:nth-child(2) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    ${(props) =>
      !props.selected
        ? 'border-color: #d7a95a; color: #d7a95a;'
        : 'border-left: 0px;'}
  }
`;
