//  Asscher
import asscherBaguetteFlank from './Asscher-Baguette.svg';
import asscherClassicSol from './Asscher-Classic.svg';
import asscherDoubleHalo from './Asscher-Dbl-Halo.svg';
import asscherWrappedHalo from './Asscher-Halo.svg';
//  Cushion
import cushionBaguetteFlank from './Cushion-Baguette.svg';
import cushionClassicSol from './Cushion-Classic.svg';
import cushionCompassSol from './Cushion-Compass.svg';
import cushionDoubleHalo from './Cushion-Dbl-Halo.svg';
import cushionWrappedHalo from './Cushion-Halo.svg';
//  Emerald
import emeraldBaguetteFlank from './Emerald-Baguettes.svg';
import emeraldClassicSol from './Emerald-Classic.svg';
import emeraldDoubleHalo from './Emerald-Dbl-Halo.svg';
import emeraldWrappedHalo from './Emerald-Halo.svg';
import emeraldThreeStone from './Emerald-Three-Stone.svg';
import emeraldTrapezoidFlank from './Emerald-Trapezoid.svg';
//  Oval
import ovalBaguetteFlank from './Oval-Baguettes.svg';
import ovalClassicSol from './Oval-Classic.svg';
import ovalCompassSol from './Oval-Compass.svg';
import ovalDoubleHalo from './Oval-Dbl-Halo.svg';
import ovalWrappedHalo from './Oval-Halo.svg';
import ovalThreeStone from './Oval-Three-Stone.svg';
//  Round
import roundBaguetteFlank from './Round-Baguette.svg';
import roundClassicSol from './Round-Classic.svg';
import roundCompassSol from './Round-Compass.svg';
import roundDoubleHalo from './Round-Dbl-Halo.svg';
import roundWrappedHalo from './Round-Halo.svg';
import roundOctagonHalo from './Round-Octagon-Halo.svg';
import roundThreeStone from './Round-Three-Stone.svg';

const heads = {
  //  Asscher
  asscherBaguetteFlank,
  asscherClassicSol,
  asscherDoubleHalo,
  asscherWrappedHalo,
  //  Cushion
  cushionBaguetteFlank,
  cushionClassicSol,
  cushionCompassSol,
  cushionDoubleHalo,
  cushionWrappedHalo,
  //  Emerald
  emeraldBaguetteFlank,
  emeraldClassicSol,
  emeraldDoubleHalo,
  emeraldWrappedHalo,
  emeraldThreeStone,
  emeraldTrapezoidFlank,
  //  Oval
  ovalBaguetteFlank,
  ovalClassicSol,
  ovalCompassSol,
  ovalDoubleHalo,
  ovalWrappedHalo,
  ovalThreeStone,
  //  Round
  roundBaguetteFlank,
  roundClassicSol,
  roundCompassSol,
  roundDoubleHalo,
  roundWrappedHalo,
  roundOctagonHalo,
  roundThreeStone,
};

export default heads;
