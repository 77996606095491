import React from 'react';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import {
  setShowDiamondIntro,
  getShowDiamondIntro,
  setSection,
  getConfigurationSaved,
  setConfigurationSaved,
} from '../../../store/ui';
import {
  getGems,
  getProductData,
  createThreekitConfiguration,
  getConfigurationAsQueryParams,
} from '../../../store/threekit';
import {
  Wrapper,
  WrapperAlt,
  Description,
  Button,
  ActionArea,
  LoadingWrapper,
} from './summary.styles';
import Table from '../../Table';
import { Header } from '../SharedComponents';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { postToCart, fetchCart } from '../../../http';
import { LOGIN_REDIRECT_URL } from '../../../constants';
import { message } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERIES } from '../../../theme';
import HeartIcon from '../../HeartIcon';
import { Spin } from 'antd';

const SummaryLanding = () => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.mobile });
  const { head, shank } = useSelector(getProductData);
  const configurationSaved = useSelector(getConfigurationSaved);
  const dispatch = useDispatch();

  const handleClickBack = () => dispatch(setSection(3));

  const handleClickSave = async () => {
    if (configurationSaved)
      return message.success({
        icon: <HeartIcon />,
        content: 'This ring has been saved to your portfolio',
      });
    if (!window.customer_email) {
      const configAsParams = getConfigurationAsQueryParams();
      window.location.href = LOGIN_REDIRECT_URL + configAsParams;
    } else {
      await createThreekitConfiguration(head, shank, undefined);
      dispatch(setConfigurationSaved(true));
      message.success({
        icon: <HeartIcon />,
        content: 'This ring has been saved to your portfolio',
      });
    }
  };

  const handleClickSelectDiamond = () => dispatch(setShowDiamondIntro(false));
  return (
    <Wrapper>
      <Header title="Diamond" />
      <Description>
        <p>
          A note on the diamonds you’re about to see: they are all beautiful. I
          know that’s to be expected, but the level of curation and selection
          that goes into the diamonds offered here is very special. First, each
          is excellent cut, which means it has found the cross-section of light
          dispersion, size, and symmetry. Second, none have fluorescence, so
          they won’t be the slightest bit hazy. And finally, every diamond here
          is the best value for its weight, color and clarity. In short, each is
          more than worthy of being in your ring.
        </p>
        <p>
          Choose a diamond and add your ring to your portfolio, then come back
          and create another one. Take your time to make your final decision,
          and contact or chat with the studio if you need some guidance. That’s
          what we are here for!
        </p>
      </Description>
      <ActionArea>
        {isMobile ? (
          <Button onClick={handleClickBack}>
            <ArrowLeftOutlined
              style={{ fontSize: '19px', marginRight: '3px' }}
            />
            Back
          </Button>
        ) : null}
        <Button onClick={handleClickSave}>
          {isMobile ? 'Save' : 'Save to Portfolio'}
        </Button>
        <Button
          style={{ marginRight: '15px' }}
          onClick={() => handleClickSelectDiamond(false)}
        >
          {isMobile ? 'Next' : 'Select Diamond'}
          <ArrowRightOutlined style={{ fontSize: '19px', marginLeft: '3px' }} />
        </Button>
      </ActionArea>
    </Wrapper>
  );
};

const DiamondSelector = () => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.mobile });
  const { head, shank, diamond } = useSelector(getProductData);
  const configurationSaved = useSelector(getConfigurationSaved);
  const gems = useSelector(getGems);
  const dispatch = useDispatch();

  const handleClickSave = async () => {
    if (configurationSaved)
      return message.success({
        icon: <HeartIcon />,
        content: 'This ring has been saved to your portfolio',
      });
    if (!window.customer_email) {
      const configAsParams = getConfigurationAsQueryParams();
      window.location.href = LOGIN_REDIRECT_URL + configAsParams;
    } else {
      await createThreekitConfiguration(head, shank, diamond);
      dispatch(setConfigurationSaved(true));
      message.success({
        icon: <HeartIcon />,
        content: 'This ring has been saved to your portfolio',
      });
    }
  };

  const handleClickBack = () => dispatch(setSection(3));

  const handleClickPurchase = async () => {
    if (!diamond)
      return message.warning('Please select a diamond to check-out with');
    try {
      const saveConfigurationResponse = await createThreekitConfiguration(
        head,
        shank,
        diamond
      );
      if (saveConfigurationResponse.status !== 200)
        return message.warning('There was a problem');

      let cartId;
      if (parseInt(window.cart_quantity)) {
        const cartResponse = await fetchCart();
        if (!cartResponse) return message.warning('There was a problem');
        cartId = cartResponse[0].id;
      }

      let data = { short_id: saveConfigurationResponse.data.shortId };
      if (cartId) {
        data['cart_id'] = cartId;
      }
      data = qs.stringify(data);

      const postToCartResponse = await postToCart(data);
      if (postToCartResponse.data.status !== 'success') {
        return message.warning('There was a problem');
      } else if (postToCartResponse.data.redirect_url) {
        window.location.href = postToCartResponse.data.redirect_url;
      } else {
        return message.warning('There was a problem');
      }
    } catch (err) {
      console.log(err);
      message.warning('There was a problem');
    }
  };

  if (!gems.length)
    return (
      <WrapperAlt>
        <Header title="Diamond" />
        <LoadingWrapper>
          <Spin size="large" />
        </LoadingWrapper>
      </WrapperAlt>
    );

  return (
    <WrapperAlt>
      <Header title="Diamond" />
      <Table />
      <ActionArea>
        {isMobile ? (
          <Button onClick={handleClickBack}>
            <ArrowLeftOutlined
              style={{ fontSize: '19px', marginRight: '3px' }}
            />
            Back
          </Button>
        ) : null}
        <Button onClick={handleClickSave}>
          {isMobile ? 'Save' : 'Save to Portfolio'}
        </Button>
        <Button onClick={handleClickPurchase}>
          Purchase
          <ArrowRightOutlined style={{ fontSize: '19px', marginLeft: '3px' }} />
        </Button>
      </ActionArea>
    </WrapperAlt>
  );
};

const Summary = () => {
  const showDiamondIntro = useSelector(getShowDiamondIntro);
  return showDiamondIntro ? <SummaryLanding /> : <DiamondSelector />;
};

export default Summary;
