import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Wrapper,
  Heading,
  Button,
  Toggle,
  SendEmailButton,
} from './modal.styles';
import { CopyOutlined, MessageOutlined } from '@ant-design/icons';
import { Input, message } from 'antd';
import { copyToClipboard } from '../../utils';
import { getConfigurationAsQueryParams } from '../../store/threekit';
import { setModal } from '../../store/ui';
import { getRenderUrls } from '../../store/threekit';
import { postEmail, postSms } from '../../http';

const ShareModal = () => {
  const dispatch = useDispatch();
  const thumbnailUrls = useSelector(getRenderUrls);
  const [showEmail, setShowEmail] = useState(true);
  const [emailTo, setEmailTo] = useState('');
  const [emailFrom, setEmailFrom] = useState('');
  const [nameFrom, setNameFrom] = useState('');
  const [nameTo, setNameTo] = useState('');
  const [cellNumber, setCellNumber] = useState('');

  const resumableLink =
    window.location.origin +
    window.location.pathname +
    getConfigurationAsQueryParams();

  const handleClickCopy = () => {
    copyToClipboard(resumableLink);
    message.success('Your link has been copied!');
  };

  const handleSendEmail = async () => {
    if (!nameFrom.length) return message.warning('Your name is required');
    if (!nameTo.length) return message.warning("Receiver's name is required");
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        emailFrom
      )
    )
      return message.warning('Your e-mail is required');
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        emailTo
      )
    )
      return message.warning("Receiver's e-mail is required");

    try {
      await postEmail({
        emailTo,
        emailFrom,
        nameTo,
        nameFrom,
        resumableLink,
        imgUrl: thumbnailUrls?.vray.ringOnly,
      });
      message.success('Email sent!');
    } catch (e) {
      message.warning('There was an issue sending your email');
    }
  };

  const handleSendSms = async () => {
    if (/^\d{10}$/.test(cellNumber)) {
      try {
        await postSms({
          smsTo: `+1${cellNumber}`,
          message: `Hey! Look at the ring ${nameFrom} designed at Lindsey Scoggins Studio: \n\n${resumableLink}`,
        });
        message.success('SMS sent!');
      } catch (e) {
        message.warning('There was an issue sending your sms');
      }
      return;
    }
    message.warning('Invalid cell number');
  };

  const handleCloseModal = () => {
    dispatch(setModal(undefined));
  };

  return (
    <Wrapper>
      <div>
        <Heading>Share Your Design</Heading>
        {/* <div>
          Copy link:
          <br />
          <Input
            disabled
            addonAfter={<CopyOutlined onClick={handleClickCopy} />}
            defaultValue={resumableLink}
            style={{ marginBottom: '24px' }}
          />
        </div> */}
        <div>
          <Toggle selected={showEmail}>
            <div onClick={() => setShowEmail(true)}>E-mail</div>
            <div onClick={() => setShowEmail(false)}>Text message</div>
          </Toggle>
        </div>
        {showEmail ? (
          <div>
            <div>Sender name:</div>
            <Input
              value={nameFrom}
              onChange={(e) => setNameFrom(e.target.value)}
              // addonAfter={<MailOutlined onClick={() => handleSendEmail()} />}
              defaultValue="Name"
              style={{ marginBottom: '12px' }}
            />
            <div>Sender e-mail:</div>
            <Input
              value={emailFrom}
              onChange={(e) => setEmailFrom(e.target.value)}
              // addonAfter={<MailOutlined onClick={() => handleSendEmail()} />}
              defaultValue="E-mail"
              style={{ marginBottom: '12px' }}
            />
            <div>Receiver name:</div>
            <Input
              value={nameTo}
              onChange={(e) => setNameTo(e.target.value)}
              // addonAfter={<MailOutlined onClick={() => handleSendEmail()} />}
              defaultValue="Name"
              style={{ marginBottom: '12px' }}
            />
            <div>Receiver e-mail:</div>
            <Input
              value={emailTo}
              onChange={(e) => setEmailTo(e.target.value)}
              // addonAfter={<MailOutlined onClick={() => handleSendEmail()} />}
              defaultValue="E-mail"
            />
            <SendEmailButton onClick={handleSendEmail}>
              Send E-mail
            </SendEmailButton>
          </div>
        ) : (
          <div>
            <div>Sender name:</div>
            <Input
              value={nameFrom}
              onChange={(e) => setNameFrom(e.target.value)}
              // addonAfter={<MailOutlined onClick={() => handleSendEmail()} />}
              defaultValue="Name"
              style={{ marginBottom: '12px' }}
            />
            <div>Send text message to:</div>
            <Input
              value={cellNumber}
              onChange={(e) => setCellNumber(e.target.value)}
              // addonAfter={<MessageOutlined onClick={() => handleSendSms()} />}
              defaultValue="Cell Number"
            />
            <SendEmailButton onClick={handleSendSms}>
              Send Text Message
            </SendEmailButton>
          </div>
        )}
        <Button onClick={handleCloseModal}>Done</Button>
      </div>
    </Wrapper>
  );
};

export default ShareModal;
