import React from 'react';
import { useSelector } from 'react-redux';
import { getModal, MODALS } from '../../store/ui';

import ShareModal from './ShareModal';

const Modal = () => {
  const modal = useSelector(getModal);

  if (modal === MODALS.share) return <ShareModal />;
  return null;
};

export default Modal;
