// export const AUTH_TOKEN = 'eb684706-cf8d-4289-96cc-2db4c41252e6'; // Admin-FTS  ->  https://lindsey-scoggins-studio.mybigcommerce.com, localhost:3000
// export const AUTH_TOKEN = '6578c17e-2f4c-4e25-b590-e704d875c0fa'; // Admin-FTS  ->  https://lindsey-scoggins-studio.mybigcommerce.com, localhost:3000
// export const ORG_ID = 'e8d72dac-5e35-461a-b499-d09ab18c342c'; // Admin-FTS
// export const AUTH_TOKEN = 'aa0d54de-deaa-4356-98a4-a165038d9c29'; // Preview  ->  https://lindsey-scoggins-studio.mybigcommerce.com, localhost:3000
// export const ORG_ID = '7c59bce1-03bb-471f-838f-903b7fb0674d'; //  Preview
export const AUTH_TOKEN =
  process.env.REACT_APP_THREEKIT_AUTH_TOKEN ||
  'aa0d54de-deaa-4356-98a4-a165038d9c29';
export const ORG_ID =
  process.env.REACT_APP_THREEKIT_ORG_ID ||
  '7c59bce1-03bb-471f-838f-903b7fb0674d';

export const ASSET_ID = '7bfff77c-ad40-4547-93df-cad52ca451ae';
export const STAGE_ID = null;

export const SERVICES_BASE_URL =
  process.env.REACT_APP_SERVICES_BASE_URL ||
  'https://lindsey-scoggins-dev.herokuapp.com';

export const INITIAL_CONFIGURATION = {
  'Diamond Size': '2ct',
  Step: 'Shape',
  Asset: { assetId: 'ec9eb4c3-25fd-4e71-87c2-9eb88e2f2c5d' },
};

export const CAMERA_OPTIONS = {
  shape: 'Shape',
  shank: 'Shank',
  head: 'Head',
  metal: 'Metal',
};

export const RENDER_URL_PREFIX =
  'https://cdn.threehub.com/clients/lindseyscoggins/renders';

export const ZOOM_LEVELS = {
  mobile: { 'Zoom Level': 'Mobile' },
  desktop: { 'Zoom Level': 'Desktop' },
};

export const LOGIN_REDIRECT_URL = `/login.php`;

export const CACHE_CONFIGURATION_REQUIREMENTS = [
  'Diamond Size',
  'Head',
  'Metal',
  'Shape',
  'Shank',
];
