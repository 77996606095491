import React from 'react';
import { DesktopWrapper, MobileWrapper } from './header.styles';

import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERIES } from '../../../../theme';

const Header = ({ title }) => {
  const DesktopHeader = () => (
    <DesktopWrapper>
      <div>choose your</div>
      <div>{title}</div>
      <hr />
    </DesktopWrapper>
  );

  const MobileHeader = () => (
    <MobileWrapper>
      <div>
        <hr />
      </div>
      <div>{title}</div>
      <div>
        <hr />
      </div>
    </MobileWrapper>
  );

  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.mobile });
  return isMobile ? <MobileHeader /> : <DesktopHeader />;
};

export default Header;
