import styled from 'styled-components';

export const Wrapper = styled.div`
  height: max-content;
  width: max-content;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    height: ${(props) => props.theme.configurator.mobile.height};
    width: 100vw;
    /* margin-top: 15px; */
  }

  @media ${(props) => props.theme.mediaQueries.mobileS} {
    height: ${(props) => props.theme.configurator.mobileS.height};
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    grid-template-columns: max-content max-content;
    display: grid;
    grid-gap: 60px;

    position: relative;
    top: 50%;
    left: ${(props) => (props.hide ? '-600px' : '0px')};
    transition: all 1s;
    transform: translateY(-50%);
  }

  & > div:nth-child(1) {
    @media ${(props) => props.theme.mediaQueries.mobile} {
      display: flex;
      width: max-content;
      margin: 12px auto;
      height: max-content;

      display: none;

      & > div {
        margin-right: 10px;
      }
      & > div:last-of-type {
        margin-right: 0px;
      }
    }

    @media ${(props) => props.theme.mediaQueries.tabletP} {
      height: max-content;
      position: relative;
      top: 55%;
      transform: translateY(-50%);

      & > div {
        margin-bottom: 15px;
      }

      & > div:last-of-type {
        margin-bottom: 0px;
      }
    }
  }

  & > div:nth-child(2) {
    height: 100%;

    @media ${(props) => props.theme.mediaQueries.mobile} {
      width: 100vw;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    @media ${(props) => props.theme.mediaQueries.tabletP} {
      width: 420px;
    }
  }
`;

export const SectionSelectorWrapper = styled.div`
  height: max-content;

  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);

  & > div {
    margin-bottom: 8px;
  }

  & > div:last-child {
    margin-bottom: 0px;
  }
`;

export const MenuItemWrapper = styled.div`
  border-radius: 50%;
  /* background: ${(props) => (props.selected ? '#C49543' : 'white')}; */
  color: ${(props) => (props.selected ? 'white' : 'black')};
  ${(props) => (!props.disabled ? 'cursor: pointer;' : null)}

  @media ${(props) => props.theme.mediaQueries.mobile} {
    height: ${(props) => props.theme.menuIconSizes.mobile};
    width: ${(props) => props.theme.menuIconSizes.mobile};
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    height: ${(props) => props.theme.menuIconSizes.tabletP};
    width: ${(props) => props.theme.menuIconSizes.tabletP};
  }

  & > div {
    height: 100%;
    width: 100%;
    /* width: 50px; */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
`;

export const ResetWrapper = styled.div`
  position: relative;
  top: -80px;

  cursor: pointer;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    height: ${(props) => props.theme.menuIconSizes.mobile};
    width: ${(props) => props.theme.menuIconSizes.mobile};
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    height: ${(props) => props.theme.menuIconSizes.tabletP};
    width: ${(props) => props.theme.menuIconSizes.tabletP};
  }

  & > div {
    height: 100%;
    width: 100%;
    /* width: 50px; */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
`;
