import axios from 'axios';
import { AUTH_TOKEN, SERVICES_BASE_URL } from '../constants';

const baseUrl = 'https://lindsey-scoggins-studio.mybigcommerce.com';
const proxyBaseUrl = 'https://lindsey-gems-and-cart-proxy.herokuapp.com';
// const threekitBaseUrl = 'https://preview.threekit.com/api';
const threekitBaseUrl = 'https://admin-fts.threekit.com/api';
// const servicesBaseUrl = 'https://lindsey-scoggins-dev.herokuapp.com';
const servicesBaseUrl = SERVICES_BASE_URL;

export const getGems = () => {
  const url = `${proxyBaseUrl}/getGems`;
  return axios.get(url);
};

export const getPartBySKU = (sku) => {
  const url = `${proxyBaseUrl}/getPartBySKU?sku=${sku}`;
  return axios.get(url);
};

export const postConfiguration = (formData) => {
  const url = `${threekitBaseUrl}/configurations?bearer_token=${AUTH_TOKEN}`;
  return axios.post(url, formData);
};

export const getSavedConfiguration = (configurationId) => {
  const url = `${threekitBaseUrl}/configurations/${configurationId}?bearer_token=${AUTH_TOKEN}`;
  return axios.get(url);
};

export const fetchCart = async () => {
  const url = `${window.location.origin}/api/storefront/carts/`;
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
  });
  const carts = await response.json();
  return carts;
};

export const postToCart = (data) => {
  const url = `https://app.lindseyscoggins.com/api/cart/index.php`;
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const postEmail = (data) => {
  const url = `${servicesBaseUrl}/email`;
  return axios.post(url, data);
};

export const postSms = (data) => {
  const url = `${servicesBaseUrl}/sms`;
  return axios.post(url, data);
};
