import React from 'react';
import { DesktopWrapper } from './app.styles';

import Modal from '../components/Modal';
import Landing from '../components/Landing';
import Configurator from '../components/Configurator';
import Player from '../components/Player';

const DesktopApp = () => {
  return (
    <>
      <DesktopWrapper>
        <div>
          <Configurator />
          <Player />
        </div>
      </DesktopWrapper>
      <Landing />
      <Modal />
    </>
  );
};

export default DesktopApp;
