const metals = [
  {
    label: '18K Yellow Gold',
    value: '18k Yellow',
    icon: '18kYellow',
  },
  {
    label: '18K Rose Gold',
    value: '18k Rose',
    icon: '18kRose',
  },
  {
    label: 'Platinum',
    value: 'Platinum',
    icon: 'Platinum',
  },
];

export default metals;
