import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSection, getDescription } from '../../../store/ui';
import {
  getShapes,
  setShape,
  getConfiguration,
  setCamera,
} from '../../../store/threekit';
import { SectionWrapper as Wrapper } from '../SharedComponents/sharedStyles.styles';

import { ActionArea, Header } from '../SharedComponents';
import { RadioButtons } from '../../InputComponents';
import { CAMERA_OPTIONS } from '../../../constants';

const Shape = () => {
  const dispatch = useDispatch();
  const shapes = useSelector(getShapes);
  const configuration = useSelector(getConfiguration);
  const { Shape: shape } = configuration || { Shape: '' };
  const description = getDescription({
    Shape: shape.toLowerCase().replace('_', ' '),
  });

  const handleSelect = val => val !== shape && dispatch(setShape(val));

  const handleClickNext = () => {
    if (!shape?.length) return;
    setCamera(CAMERA_OPTIONS.head);
    dispatch(setSection(1));
  };

  return (
    <Wrapper>
      <Header title="Diamond Shape" />
      <div>
        <RadioButtons options={shapes} onClick={handleSelect} value={shape} />
      </div>
      <ActionArea
        hide={!shape.length}
        onClick={handleClickNext}
        heading={
          shape?.length ? shapes.find(el => el.value === shape)?.label : ''
        }
        buttonText="Head"
        description={description}
      />
    </Wrapper>
  );
};

export default Shape;
