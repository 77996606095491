import styled from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;

  & > div:nth-child(1) {
    text-align: center;

    img {
      width: 50px;
      height: 100%;
      object-fit: contain;
      transform: ${props =>
        props.selected === '1ct' ? 'scaleX(-1)' : 'scaleX(1)'};

      transition: all 0.2s;

      @media ${props => props.theme.mediaQueries.mobile} {
        width: 36px;
      }

      @media ${props => props.theme.mediaQueries.tabletP} {
        width: 50px;
      }
    }
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: row;

    @media ${props => props.theme.mediaQueries.mobile} {
      height: 25px;
      width: 50px;
    }

    @media ${props => props.theme.mediaQueries.tabletP} {
      height: 40px;
      width: 80px;
    }

    & > div {
      height: max-content;
      width: 40px;
      text-align: center;

      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    & > div:nth-child(1) {
      img {
        @media ${props => props.theme.mediaQueries.mobile} {
          height: 20px;
          width: 20px;
        }

        @media ${props => props.theme.mediaQueries.tabletP} {
          height: 24px;
          width: 24px;
        }
      }
    }

    & > div:nth-child(2) {
      img {
        @media ${props => props.theme.mediaQueries.mobile} {
          height: 28px;
          width: 28px;
        }

        @media ${props => props.theme.mediaQueries.tabletP} {
          height: 36px;
          width: 36px;
        }
      }
    }
  }
`;
