import styled from 'styled-components';
import { landing as landingImg } from '../../assets';

export const Wrapper = styled.div`
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: black;
  background-size: cover;
  background-position: center;

  transform: translateY(${(props) => (props.show ? '0px' : '-100%')});
  transition: all 0.6s;

  p {
    font-family: 'EB Garamond', serif;
  }

  @media ${(props) => props.theme.mediaQueries.mobile} {
    height: ${(props) => props.theme.mobile.height};
    background-image: url(${landingImg});
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    height: 100vh;
    min-height: calc(
      ${(props) => props.theme.playerSizes.tabletP.height} + 30px
    );

    background-image: url(${landingImg});
  }

  & > div:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);

    @media ${(props) => props.theme.mediaQueries.mobile} {
      height: ${(props) => props.theme.mobile.height};
    }

    @media ${(props) => props.theme.mediaQueries.tabletP} {
      height: 100vh;
      min-height: calc(
        ${(props) => props.theme.playerSizes.tabletP.height} + 30px
      );
    }
  }

  & > div:nth-child(2) {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    height: max-content;

    @media ${(props) => props.theme.mediaQueries.mobile} {
      width: 100%;
      padding: 0 20px;
    }

    @media ${(props) => props.theme.mediaQueries.tabletP} {
      width: max-content;
      left: 15vw;
    }
  }
`;

export const Heading = styled.div`
  font-family: BruneiDarussalam;
  color: ${(props) => props.theme.colors.gold};

  @media ${(props) => props.theme.mediaQueries.mobile} {
    font-size: 60px;
    text-align: center;
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    font-size: 80px;
  }
`;

export const Body = styled.div`
  margin: 20px 0;
  color: white;
  line-height: 1.5em;
  font-family: 'EB Garamond', serif;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    font-size: 14px;
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    width: 420px;
  }
`;

export const Footer = styled.div`
  font-family: BruneiDarussalam;
  color: white;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    font-family: BruneiDarussalam;
  }

  @media ${(props) => props.theme.mediaQueries.mobile} {
    font-size: 40px;
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    font-size: 50px;
  }

  & > div:nth-child(2) {
    cursor: pointer;
    color: ${(props) => props.theme.colors.gold};
  }
`;
