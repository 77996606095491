import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSection, getDescription } from '../../../store/ui';
import {
  getHeads,
  setHead,
  getConfiguration,
  setCamera,
} from '../../../store/threekit';
import labelsMap from '../../../data/labels.json';

import { SectionWrapper as Wrapper } from '../SharedComponents/sharedStyles.styles';

import { ActionArea, Header } from '../SharedComponents';
import { RadioButtons } from '../../InputComponents';
import { CAMERA_OPTIONS } from '../../../constants';

const Head = () => {
  const dispatch = useDispatch();
  const heads = useSelector(getHeads);
  const configuration = useSelector(getConfiguration);
  const { Head: head } = configuration || { Head: '' };
  const description = getDescription({
    Head:
      labelsMap[head.toLowerCase().replace('_', ' ')] ||
      head.toLowerCase().replace('_', ' '),
  });

  const handleSelect = val => val !== head && dispatch(setHead(val));

  const handleClickNext = () => {
    if (!head) return;
    setCamera(CAMERA_OPTIONS.shank);
    dispatch(setSection(2));
  };

  const handleClickBack = () => {
    setCamera(CAMERA_OPTIONS.shape);
    dispatch(setSection(0));
  };

  return (
    <Wrapper>
      <Header title="Setting Style" />
      <div>
        <RadioButtons options={heads} onClick={handleSelect} value={head} />
      </div>
      <ActionArea
        hide={!head.length}
        onClick={handleClickNext}
        onClickBack={handleClickBack}
        heading={
          head?.length
            ? labelsMap[heads.find(el => el.value === head)?.label] ||
              heads.find(el => el.value === head)?.label
            : ''
        }
        buttonText="Shank"
        description={description}
      />
    </Wrapper>
  );
};

export default Head;
