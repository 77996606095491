import styled from 'styled-components';

export const SectionWrapper = styled.div`
  height: 100%;
  /* min-height: 650px; */
  height: 600px;
  display: grid;
  grid-template-rows: max-content auto max-content;

  color: black;

  @media ${props => props.theme.mediaQueries.mobile} {
    height: 100%;
    max-width: 100vw;
    grid-template-rows: max-content max-content auto;

    & > div {
      max-width: 100vw;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

export const ToggleWrapper = styled.div`
  position: relative;
  height: max-content;
  width: max-content;

  @media ${props => props.theme.mediaQueries.mobile} {
    top: calc(100% - 25px);
    left: 25px;
    transform: translateY(-100%);
  }

  @media ${props => props.theme.mediaQueries.tabletP} {
    /* top: calc(100% - 20px); */
    left: 20px;
    transform: translateY(-100%);
  }
`;
