import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { validate as uuidValidate } from 'uuid';
import {
  AUTH_TOKEN,
  ORG_ID,
  ASSET_ID,
  STAGE_ID,
  INITIAL_CONFIGURATION,
  RENDER_URL_PREFIX,
  ZOOM_LEVELS,
} from '../constants';
import { rules, metals, order, skinTones as skinTonesData } from '../data';
import labelsMap from '../data/labels.json';
import {
  getPartBySKU,
  getSavedConfiguration,
  postConfiguration,
} from '../http';
import { camelize, getParams } from '../utils';
import {
  LANDING,
  restoreConfiguration as restoreUiConfiguration,
  setConfigurationSaved,
  setLanding,
  setSection,
} from './ui';
import { filterConfigurationForCache, cacheConfiguration } from '../utils';

import { getGems as getGemsData } from '../http';

let gems = [];

export const getGemBySku = (sku) => {
  if (!sku) return;
  return gems.find((gem) => gem.SKU === sku);
};

const RESTORE_FROM = {
  url: 'url',
  login: 'login',
  wishlist: 'wishlist',
  localStorage: 'localStorage',
};

const getSkuFromConfiguration = (configuration) =>
  new Promise((resolve) => {
    if (!configuration) return resolve({ head: undefined, shank: undefined });
    const {
      Shape,
      Head,
      Shank,
      'Diamond Size': diamondSize,
      Metal,
      Diamond,
    } = configuration;
    if (
      !Shape ||
      !Shape.length ||
      !Head ||
      !Head.length ||
      !diamondSize ||
      !diamondSize.length ||
      !Shank ||
      !Shank.length ||
      !Metal ||
      !Metal.length
    )
      return resolve({ head: undefined, shank: undefined, diamond: undefined });

    const [shankCategory, shankName, shankType] = Shank.split('-');
    const metalMap = { Platinum: 'p', '18k Rose': 'r', '18k Yellow': 'y' };
    const anotherDiamondSize = diamondSize === '1ct' ? '2ct' : '1ct';
    const head = rules[Shape]?.[diamondSize]?.HEADS[Head] + metalMap[Metal];
    const shank =
      rules[Shape]?.[diamondSize]?.SHANKS[shankCategory]?.[shankName]?.[
        shankType
      ]?.[Head] + metalMap[Metal];
    const anotherHead =
      rules[Shape]?.[anotherDiamondSize]?.HEADS[Head] + metalMap[Metal];
    const anotherShank =
      rules[Shape]?.[anotherDiamondSize]?.SHANKS[shankCategory]?.[shankName]?.[
        shankType
      ]?.[Head] + metalMap[Metal];
    try {
      let data;
      Promise.all(
        [head, anotherHead, shank, anotherShank].map((el) => getPartBySKU(el))
      ).then((results) => {
        data = results.map(({ data }) => ({
          sku: data.sku,
          price: data.price,
          variantId: data.id,
          productId: data.product_id,
        }));

        if (!Diamond || !Diamond.length)
          return resolve({
            head: {
              [diamondSize]: data[0],
              [anotherDiamondSize]: data[1],
            },
            shank: {
              [diamondSize]: data[2],
              [anotherDiamondSize]: data[3],
            },
            diamond: undefined,
          });

        const gem = getGemBySku(Diamond);
        return resolve({
          head: {
            [diamondSize]: data[0],
            [anotherDiamondSize]: data[1],
          },
          shank: {
            [diamondSize]: data[2],
            [anotherDiamondSize]: data[3],
          },
          diamond: gem
            ? { productId: gem.Id, sku: gem.SKU, price: gem.Price }
            : undefined,
        });
      });
    } catch (err) {
      if (!Diamond || !Diamond.length)
        return resolve({
          head: undefined,
          shank: undefined,
          diamond: undefined,
        });

      const gem = getGemBySku(Diamond);
      return resolve({
        head: undefined,
        shank: undefined,
        diamond: { productId: gem.Id, sku: gem.SKU, price: gem.Price },
      });
    }
  });

const getRenderUrlsFromConfiguration = (config) => {
  if (!config) return null;
  if (!config.Metal?.length || !config.Shank?.length) return null;
  const attrOrder = ['Diamond Size', 'Shape', 'Head', 'Shank', 'Metal'];
  const baseConfigName = attrOrder
    .map((attrName) => {
      return config[attrName].replace(/ /g, '');
    })
    .join('__');
  const webGLViews = ['Front', 'Top', 'Side'];
  const webGLUrls = webGLViews.reduce((prev, viewName) => {
    // let url = RENDER_URL_PREFIX;
    prev[
      viewName
    ] = `${RENDER_URL_PREFIX}/webgl/${baseConfigName}__${viewName}.jpg`;
    return prev;
  }, {});

  const ringOnlyUrl = `${RENDER_URL_PREFIX}/vray/ring-only-2k/${baseConfigName}.jpg`;

  const skinTones = Object.keys(skinTonesData);
  const handUrls = skinTones.reduce((prev, skinTone) => {
    prev[
      skinTone
    ] = `${RENDER_URL_PREFIX}/vray/hands-2k/${baseConfigName}__${skinTone}.jpg`;
    return prev;
  }, {});

  return { webgl: webGLUrls, vray: { ringOnly: ringOnlyUrl, hands: handUrls } };
};

const setConfiguration = createAction(
  'threekit/setConfiguration',
  (configuration) => ({
    payload:
      configuration || window.threekitApi.configurator.getConfiguration(),
  })
);

const fetchProductData = createAsyncThunk(
  'threekit/fetchProductData',
  (configuration) =>
    getSkuFromConfiguration(
      configuration || window.threekitApi.configurator.getConfiguration()
    )
);

export const fetchGemsData = createAsyncThunk(
  'threekit/fetchGemsData',
  () =>
    new Promise(async (resolve) => {
      try {
        const { data } = await getGemsData();
        data.forEach((gem) => {
          const geminfo = {};
          geminfo.Price = gem.price;
          geminfo.Id = gem.id;
          geminfo.SKU = gem.sku;
          if (gem.custom_fields === undefined) return [];

          gem.custom_fields.forEach((field) => {
            geminfo[field.name] = field.value;
          });
          gems.push(geminfo);
        });
      } catch (err) {
        console.log('error fetching gems');
        console.log(err);
      } finally {
        resolve();
      }
    })
);

export const setDiamond = createAsyncThunk(
  'threekit/setDiamond',
  async (diamond, { dispatch }) => {
    if (!window.threekitApi) return;
    await window.threekitApi.configurator.setConfiguration({
      Diamond: diamond || '',
    });

    dispatch(setConfigurationSaved(false));

    const configuration = window.threekitApi.configurator.getConfiguration();
    dispatch(setConfiguration(configuration));
    dispatch(fetchProductData(configuration));
  }
);

export const setMetal = createAsyncThunk(
  'threekit/setMetal',
  async (metal, { dispatch }) => {
    if (!window.threekitApi) return;

    await setThreekitConfigurationWithReveal({ Metal: metal });
    // await window.threekitApi.configurator.setConfiguration({
    //   Metal: metal,
    // });

    dispatch(setConfigurationSaved(false));

    const configuration = window.threekitApi.configurator.getConfiguration();
    cacheConfiguration(configuration);
    dispatch(setConfiguration(configuration));
    dispatch(fetchProductData(configuration));
  }
);

export const setShank = createAsyncThunk(
  'threekit/setShank',
  async (shank, { dispatch }) => {
    if (!window.threekitApi) return;

    await setThreekitConfigurationWithReveal({ Shank: shank });
    // await window.threekitApi.configurator.setConfiguration({
    //   Shank: shank,
    // });

    dispatch(setConfigurationSaved(false));

    const configuration = window.threekitApi.configurator.getConfiguration();
    cacheConfiguration(configuration);
    dispatch(setConfiguration(configuration));
    dispatch(fetchProductData(configuration));
  }
);

export const setShape = createAsyncThunk(
  'threekit/setShape',
  async (shape, { getState, dispatch }) => {
    if (!window.threekitApi) return;
    const state = getState();
    const { configuration } = state.threekit;
    let config = { Shape: shape };

    //  Exception #1
    if (
      configuration.Shape === 'round' &&
      configuration.Head === 'octagon_halo' &&
      config.Shape !== 'round'
    ) {
      Object.assign(config, { Head: 'wrapped_halo' });

      //  Exception #2
    } else if (configuration.Head === 'compass_sol') {
      const headOptions = Object.keys(
        rules[config.Shape][configuration['Diamond Size']].HEADS
      );

      if (!headOptions.includes(configuration.Head))
        Object.assign(config, { Head: 'classic_sol' });
    } else if (configuration.Head && configuration.Head.length) {
      //  When updating the shape, if there is a Head already selected
      //  we need to make sure its compatible with the updated Shape
      const headOptions = Object.keys(
        rules[config.Shape][configuration['Diamond Size']].HEADS
      );

      //  If the headOptions for the updated Shape doesn't include
      //  the presently selected Head, we updated the Head to the
      //  first possible selection
      if (!headOptions.includes(configuration.Head)) {
        // Object.assign(config, { Head: headOptions[0] });
        Object.assign(config, { Head: EMPTY_VALUE });

        if (configuration.Shank && configuration.Shank.length)
          Object.assign(config, { Shank: EMPTY_VALUE, Metal: EMPTY_VALUE });

        //  If there is a shank selection as well (which can only happen if
        //  a head is selected) we need to make ensure its compatibility
      } else if (configuration.Shank && configuration.Shank.length) {
        // Get new shank in same category & subcategory (ie same row of the
        // configuration spreadsheet) which matches the new head (if one exists)
        const [category, subcategory] = configuration.Shank.split('-');
        const shanksData =
          rules[configuration.Shape][configuration['Diamond Size']].SHANKS[
            category
          ][subcategory];

        const newShankType = getNewShankType(
          config.Head || configuration.Head,
          shanksData
        );

        if (newShankType) {
          const newShank = [category, subcategory, newShankType].join('-');
          Object.assign(config, { Shank: newShank });
        } else
          Object.assign(config, { Shank: EMPTY_VALUE, Metal: EMPTY_VALUE });
      }
    }

    await setThreekitConfigurationWithReveal(config);
    // await window.threekitApi.configurator.setConfiguration(config);

    dispatch(setConfigurationSaved(false));

    const updatedConfiguration =
      window.threekitApi.configurator.getConfiguration();
    cacheConfiguration(updatedConfiguration);
    dispatch(setConfiguration(updatedConfiguration));
    dispatch(fetchProductData(updatedConfiguration));
  }
);

export const setHead = createAsyncThunk(
  'threekit/setHead',
  async (head, { getState, dispatch }) => {
    if (!window.threekitApi) return;
    const state = getState();
    const { configuration } = state.threekit;

    let config = { Head: head };

    if (configuration.Shank && configuration.Shank.length) {
      // Get new shank in same category & subcategory (ie same row of the
      // configuration spreadsheet) which matches the new head (if one exists)
      const [category, subcategory] = configuration.Shank.split('-');
      const shanksData =
        rules[configuration.Shape][configuration['Diamond Size']].SHANKS[
          category
        ][subcategory];

      const newShankType = getNewShankType(config.Head, shanksData);

      if (newShankType) {
        const newShank = [category, subcategory, newShankType].join('-');
        Object.assign(config, { Shank: newShank });
      } else Object.assign(config, { Shank: EMPTY_VALUE, Metal: EMPTY_VALUE });
    }

    await setThreekitConfigurationWithReveal(config);
    // await window.threekitApi.configurator.setConfiguration(config);

    dispatch(setConfigurationSaved(false));

    const updatedConfiguration =
      window.threekitApi.configurator.getConfiguration();
    cacheConfiguration(updatedConfiguration);
    dispatch(setConfiguration(updatedConfiguration));
    dispatch(fetchProductData(updatedConfiguration));
  }
);

export const setDiamondSize = createAsyncThunk(
  'threekit/setDiamondSize',
  async (diamondSize, { getState, dispatch }) => {
    if (!window.threekitApi) return;
    const state = getState();
    const { configuration } = state.threekit;

    let config = { 'Diamond Size': diamondSize };

    //  When updating the Diamond Size, if there is a Head already slected
    //  we need to make sure its compatible with the updated Shape
    if (configuration.Head && configuration.Head.length) {
      const headOptions = Object.keys(
        rules[configuration.Shape][diamondSize].HEADS
      );

      //  If the headOptions for the updated Shape doesn't include
      //  the presently selected Head, we updated the Head to the
      //  first possible selection
      if (!headOptions.includes(configuration.Head))
        Object.assign(config, { Head: headOptions[0] });

      //  If there is a shank selection as well (which can only happen if
      //  a head is selected) we need to make ensure its compatibility
      if (configuration.Shank && configuration.Shank.length) {
        const shanksData = rules[configuration.Shape][diamondSize].SHANKS;

        //  We compile a list of Shanks compatible with
        //  the updated Shape (updated?) Head.
        const shankOptions = Object.entries(shanksData).reduce(
          (output, [category, shanks]) => {
            Object.entries(shanks).forEach(([shank, types]) => {
              Object.entries(types).forEach(([type, vals]) => {
                if (
                  Object.keys(vals).includes(config.Head || configuration.Head)
                ) {
                  output.push(`${category}-${shank}-${type}`);
                }
              });
            });
            return output;
          },
          []
        );

        //  If that list does not include the current Shank
        //  we set the set it to the first item in that list
        if (!shankOptions.includes(configuration.Shank))
          Object.assign(config, { Shank: shankOptions[0] });
      }
    }

    await setThreekitConfigurationWithReveal(config);
    // await window.threekitApi.configurator.setConfiguration(config);

    dispatch(setConfigurationSaved(false));

    const updatedConfiguration =
      window.threekitApi.configurator.getConfiguration();
    cacheConfiguration(updatedConfiguration);
    dispatch(setConfiguration(updatedConfiguration));
    dispatch(fetchProductData(updatedConfiguration));
  }
);

const initialState = {
  configuration: null,
  headProduct: undefined,
  shankProduct: undefined,
  diamondProduct: undefined,
  gemsFetched: false,
};

const EMPTY_VALUE = '';

const DEFAULT_ICONS = {
  Shape: 'round',
  Head: 'roundCompassSol',
  Shank: 'threePhasesTripleRow',
  Metal: 'Platinum',
  Summary: 'Summary',
};

function getNewShankType(newHead, shanksData) {
  for (let type in shanksData) {
    for (let head in shanksData[type]) {
      if (head === newHead) return type;
    }
  }
  return null;
}

const { actions, reducer } = createSlice({
  name: 'threekit',
  initialState,
  reducers: {
    clearState: (state, action) => {
      if (!window.threekitApi) return;

      const config = {
        Head: EMPTY_VALUE,
        Shape: EMPTY_VALUE,
        Shank: EMPTY_VALUE,
        Metal: EMPTY_VALUE,
      };

      window.threekitApi.configurator.setConfiguration(config);

      state.configuration = window.threekitApi.configurator.getConfiguration();
      state.headProduct = initialState.headProduct;
      state.shankProduct = initialState.shankProduct;
      state.diamondProduct = initialState.diamondProduct;

      localStorage.removeItem('configuration');
    },
    setConfigurator: (state, action) => {
      state.configuration = action.payload;
    },
    // setDiamondProduct: (state, action) => {
    //   state.diamondProduct = action.payload || undefined;
    // },
  },
  extraReducers: {
    [setConfiguration]: (state, action) => {
      state.configuration = action.payload;
    },
    [fetchGemsData.fulfilled]: (state, action) => {
      state.gemsFetched = true;
    },
    [fetchProductData.fulfilled]: (state, action) => {
      state.headProduct = action.payload.head;
      state.shankProduct = action.payload.shank;
      state.diamondProduct = action.payload.diamond;
    },
  },
});

export const { clearState, setConfigurator /* setDiamondProduct */ } = actions;

export const initializePlayer =
  ({ isMobile }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const isRestoredConfiguration = await restoreConfiguration();

      if (isRestoredConfiguration) dispatch(setLanding(LANDING.resume));

      const threekitApi = await window.threekitPlayer({
        el: document.getElementById('player-root'),
        authToken: AUTH_TOKEN,
        assetId: ASSET_ID,
        stageId: STAGE_ID,
        orgId: ORG_ID,
        initialConfiguration: Object.assign(
          {},
          INITIAL_CONFIGURATION,
          isRestoredConfiguration ? isRestoredConfiguration.configuration : {},
          isMobile ? ZOOM_LEVELS.mobile : ZOOM_LEVELS.desktop
        ),
      });
      if (!window.threekitPlayer) reject('Error initializing player');

      /***** API SETUP START ***************************************************************/
      //  Enables access to the threekit store api
      threekitApi.enableApi('store');

      //  Enables access to the threekit player api
      window.threekitApi = {
        api: threekitApi,
        player: threekitApi.enableApi('player'),
      };
      /***** API SETUP END *****************************************************************/

      /***** PLAYER TOOLS START ************************************************************/
      //  The functional interactions with the player can be removed
      //  either individually or as an array

      // threekitApi.tools.removeTool('pan');

      threekitApi.tools.removeTools(['pan', 'zoom']);

      //  Enables full rotation on mobile
      threekitApi.tools.setTool('orbit', {
        options: { turnTableMobileOnly: false },
      });
      /***** PLAYER TOOLS END **************************************************************/

      /***** PLAYER LIFECYCLE LISTENSERS START *********************************************/
      //  We add listeners to be triggered during the player's
      //  lifecycle events: PRELOADED, LOADED and RENDERED

      threekitApi.on(threekitApi.scene.PHASES.LOADED, async () => {
        //    Assigns default configurator to window object
        window.threekitApi.configurator = threekitApi.player.getConfigurator();

        const updatedConfiguration =
          window.threekitApi.configurator.getConfiguration();
        dispatch(setConfiguration(updatedConfiguration));
        dispatch(fetchProductData(updatedConfiguration));
        dispatch(fetchGemsData());

        if (isRestoredConfiguration) {
          switch (isRestoredConfiguration.restoreFrom) {
            case RESTORE_FROM.login:
              const { head, shank, diamond } = await getSkuFromConfiguration(
                updatedConfiguration
              );
              await createThreekitConfiguration(head, shank, diamond);
              break;
            case RESTORE_FROM.localStorage:
            case RESTORE_FROM.url:
              dispatch(
                restoreUiConfiguration(!!updatedConfiguration.Diamond?.length)
              );
              break;
            default:
              dispatch(
                restoreUiConfiguration(!!updatedConfiguration.Diamond?.length)
              );
              break;
          }
        }
      });

      // threekitApi.on(threekitApi.scene.PHASES.LOADED, () => {
      //  initialSettings.onLoad ? initialSettings.onLoad() : console.log('Player has loaded all data');
      // });

      //  threekitApi.on(threekitApi.scene.PHASES.RENDERED, () => {
      //      initialSettings.onRender ? initialSettings.onRender() : console.log('Player has rendered the default asset')
      //  });
      /***** PLAYER LIFECYCLE LISTENSERS END ***********************************************/

      resolve(true);
    });
  };

export const getConfiguration = (state) => state.threekit.configuration;

export const getGems = (state) => {
  if (!state.threekit.gemsFetched) return [];

  if (!state.threekit.configuration?.Shape?.length)
    return gems.sort((a, b) => a['Weight'] - b['Weight']);

  const shape = state.threekit.configuration.Shape.toLowerCase();
  return gems
    .filter((el) => shape === el.Shape.toLowerCase())
    .sort((a, b) => a['Weight'] - b['Weight']);
};

export const getShapes = () => {
  const shapesData = Object.keys(rules);
  const shapes = Array(shapesData.length).fill(null);

  return shapesData.reduce((output, shape) => {
    const label =
      labelsMap[shape.replaceAll('_', ' ')] || shape.replaceAll('_', ' ');

    output[order.shapes.indexOf(shape)] = {
      label,
      value: shape,
      icon: camelize(shape.replaceAll('_', ' ')),
    };
    return output;
  }, shapes);
};

export const getHeads = (state) => {
  const shape = state.threekit.configuration?.Shape;
  if (!shape || shape === EMPTY_VALUE) return [];
  const diamondSize = state.threekit.configuration?.['Diamond Size'] || '1ct';
  const headsData = rules[shape][diamondSize].HEADS;
  const headsOrder = order.heads;
  const heads = Array(Object.keys(headsData).length).fill(null);

  return Object.entries(headsData)
    .reduce((output, [head, val]) => {
      const label = labelsMap[head] || head.replaceAll('_', ' ');
      output[headsOrder.indexOf(label)] = {
        key: val,
        label,
        value: head,
        icon: camelize(shape + ' ' + head.replaceAll('_', ' ')),
      };
      return output;
    }, heads)
    .filter((el) => !!el);
};

export const getShanks = (state) => {
  const shape = state.threekit.configuration?.Shape;
  const head = state.threekit.configuration?.Head;
  if (!shape || shape === EMPTY_VALUE || !head || head === EMPTY_VALUE)
    return [];
  const diamondSize = state.threekit.configuration?.['Diamond Size'] || '1ct';

  const shanksData = rules[shape][diamondSize].SHANKS;
  let output = {};

  Object.entries(shanksData).forEach(([category, shanks]) => {
    let data = {};
    Object.entries(shanks).forEach(([shank, types]) => {
      Object.entries(types).forEach(([type, vals]) => {
        if (Object.keys(vals).includes(head)) {
          const label = labelsMap[shank] || shank.replaceAll('_', ' ');
          const option = {
            label,
            value: `${category}-${shank}-${type}`,
            icon: camelize(
              `${category} ${shank}`.toLowerCase().replaceAll('_', ' ')
            ),
          };
          if (data[shank]) data[shank] = [...data[shank], option];
          else data[shank] = option;
        }
      });
    });

    if (Object.keys(data).length > 0) {
      output[category] = Array(order.shanks[category]).fill(null);
      Object.entries(data).forEach(([shank, shankData]) => {
        const shankName = labelsMap[shank] || shank.replaceAll('_', ' ');
        output[category][order.shanks[category].indexOf(shankName)] = shankData;
      });
      output[category].filter((el) => !!el);
    }
  });
  return output;
};

export const getSectionIcons = (state) => {
  const defaults = { ...DEFAULT_ICONS };

  const shape = state.threekit.configuration?.Shape || '';
  const head = state.threekit.configuration?.Head || '';
  const shank = state.threekit.configuration?.Shank || '';
  const metal = state.threekit.configuration?.Metal || '';

  if (shape.length)
    Object.assign(defaults, {
      Shape: shape,
      // Head: shape + defaults.Head,
    });
  // else Object.assign(defaults, { Head: defaults.Shape + defaults.Head });

  if (head.length)
    Object.assign(defaults, {
      Head: camelize(defaults.Shape + ' ' + head.replaceAll('_', ' ')),
    });

  if (shank.length) {
    const [category, shankName] = shank.split('-');
    Object.assign(defaults, {
      Shank: camelize(
        `${category} ${shankName}`.toLowerCase().replaceAll('_', ' ')
      ),
    });
  }

  if (metal.length) Object.assign(defaults, { Metal: metal.replace(' ', '') });

  return Object.values(defaults);
};

export const getMetals = () => metals;

export const getRenderUrls = (state) =>
  getRenderUrlsFromConfiguration(state.threekit.configuration);

export const setCamera = async (view) => {
  if (!window.threekitApi) return;
  await window.threekitApi.configurator.setConfiguration({ Step: view });
};

export const getProductData = (state) => ({
  head: state.threekit.headProduct,
  shank: state.threekit.shankProduct,
  diamond: state.threekit.diamondProduct,
});

export const createThreekitConfiguration = (head, shank, diamond) => {
  const ringData = diamond ? { head, shank, diamond } : { head, shank };
  const customerId = window.customer_email
    ? window.customer_email
    : 'amandeep@coalitiontechnologies.com';

  const { webgl, vray } = getRenderUrlsFromConfiguration(
    window.threekitApi.configurator.getConfiguration()
  );
  ringData.renders = { webgl, vray: vray.ringOnly }; // wishlist uses 3 webgl + vray hands render
  ringData.customerId = customerId;

  const fd = new FormData();
  fd.append('productId', ASSET_ID);
  fd.append('customerId', customerId);
  fd.append('productVersion', 'v1');
  fd.append('metadata', JSON.stringify(ringData));
  fd.append(
    'variant',
    JSON.stringify(window.threekitApi.configurator.getConfiguration())
  );

  return postConfiguration(fd);
};

const restoreConfiguration = (initialConfiguration) =>
  new Promise(async (resolve) => {
    const params = getParams();
    let output;

    if (params.preset && uuidValidate(params.preset)) {
      const { data, status } = await getSavedConfiguration(params.preset);
      if (status !== 200) return resolve(false);
      if ('Zoom Level' in data.variant) delete data.variant['Zoom Level'];
      // await window.threekitApi.configurator.setConfiguration({
      //   ...initialConfiguration,
      //   ...data.variant,
      // });
      // resolve(RESTORE_FROM.wishlist);
      output = {
        restoreFrom: RESTORE_FROM.wishlist,
        configuration: data.variant,
      };
    } else if (params.login === 'yes') {
      if ('Zoom Level' in params) delete params['Zoom Level'];
      // await window.threekitApi.configurator.setConfiguration({
      //   ...initialConfiguration,
      //   ...params,
      // });
      // resolve(RESTORE_FROM.login);
      output = {
        restoreFrom: RESTORE_FROM.login,
        configuration: params,
      };
    } else if (params.Shape) {
      if ('Zoom Level' in params) delete params['Zoom Level'];
      // await window.threekitApi.configurator.setConfiguration({
      //   ...initialConfiguration,
      //   ...params,
      // });
      // resolve(RESTORE_FROM.url);
      output = {
        restoreFrom: RESTORE_FROM.url,
        configuration: params,
      };
    } else if (localStorage.configuration) {
      const config = JSON.parse(localStorage.configuration);
      if (!filterConfigurationForCache(config)) resolve(output);
      if ('Zoom Level' in config) delete config['Zoom Level'];
      // await window.threekitApi.configurator.setConfiguration({
      //   ...initialConfiguration,
      //   ...config,
      // });
      // resolve(RESTORE_FROM.localStorage);
      output = {
        restoreFrom: RESTORE_FROM.localStorage,
        configuration: filterConfigurationForCache(config),
      };
    }
    resolve(output);
  });

export const getConfigurationAsQueryParams = (additionalParams = {}) => {
  if (!window.threekitApi) return null;
  const config = window.threekitApi.configurator.getConfiguration();
  if ('Zoom Level' in config) delete config['Zoom Level'];
  return Object.entries({ ...config, ...additionalParams }).reduce(
    (output, [key, value], idx) => {
      const property = `${encodeURIComponent(key)}=${
        typeof value === 'string'
          ? encodeURIComponent(value)
          : encodeURIComponent(JSON.stringify(value))
      }`;
      if (!idx) output += property;
      else output += `&${property}`;
      return output;
    },
    '?'
  );
};

const OPAQUE_FRAME_START = 0.05; // seconds until the transparent first frame(s) are done and the first opaque frame appears. This is where we need to start playing from.
async function playOverlayAnimation() {
  const { Step, 'Zoom Level': zoomLevel } =
    window.threekitApi.configurator.getConfiguration();

  // determine video element id
  const videoElId = `tk_ls_overlayAnimation_${Step}_${zoomLevel}`;
  const video = document.getElementById(videoElId);
  if (!video) {
    console.error(`Could not find video for step ${Step}`);
    return;
  }

  // if (video.audioTracks && video.audioTracks.length)
  //   video.audioTracks[0].enabled = false;
  video.muted = true;
  video.setAttribute('playsinline', 'playsinline');

  video.currentTime = OPAQUE_FRAME_START;

  const seeked = new Promise((resolve) => {
    video.onseeked = (event) => {
      video.onseeked = null;
      resolve();
    };
  });

  await seeked;
  await video.play();
}

function later(delay) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay);
  });
}

const setThreekitConfigurationWithReveal = async (config) => {
  const { configurator, player } = window.threekitApi;

  player.store.killTimer(player.id);
  await configurator.setConfiguration(config);
  await playOverlayAnimation();

  // For some reason sometimes there is still one frame of the player with the
  // new configuration before the video kicks in. This workaround adds a tiny
  // delay in an attempt to make sure the video starts before we resume
  // rendering.
  await later(10);

  // Resume rendering
  player.store.addTimer(player.id, (delta) => {
    player.translator.update(delta);
  });
};

export default reducer;
