import styled from 'styled-components';
import {
  paperDesktop as paperDesktopImg,
  paperMobile as paperMobileImg,
} from '../../assets';

export const DesktopWrapper = styled.div`
  height: ${(props) => props.theme.playerSizes.tabletP.height};
  width: ${(props) => props.theme.playerSizes.tabletP.width};

  position: relative;
  top: 50%;
  left: ${(props) => (props.hide ? '600px' : '0px')};
  transition: all 1s;
  transform: translateY(-50%);

  ${(props) =>
    props.showPlayer ? `background-image: url(${paperDesktopImg});` : ''}

  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  overflow: hidden;

  #player-root {
    opacity: ${(props) => (props.showPlayer ? 1 : 0)};

    height: 100%;
    width: 100%;
    margin: 0 auto;

    & > div:first-child > div:first-child > div:first-child > div:nth-child(3) {
      display: none;
    }
  }
`;

export const MobileWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 8px;

  height: max-content;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 12px;

  & > div:nth-child(1) {
    padding: 10px 0;
  }

  & > div:nth-child(2) {
    height: 0;
    width: 100%;
    padding-top: 75%;

    @media ${(props) => props.theme.mediaQueries.mobileS} {
      padding-top: 70%;
    }

    ${(props) => (!props.showPlayer ? 'pointer-events: none' : '')};

    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    ${(props) =>
      props.showPlayer ? `background-image: url(${paperMobileImg});` : ''}
  }

  #player-root {
    height: 100%;
    width: calc(100vw - ((2 * 12px) + 50px + 8px));
    opacity: ${(props) => (props.showPlayer ? 1 : 0)};

    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    & > div:first-child > div:first-child > div:first-child > div:nth-child(3) {
      display: none;
    }
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;

  box-shadow: 5px 5px 20px #999999;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    top: 0;
    left: 50%;
    height: 100%;
    width: max-content;
    transform: translateX(-40%);

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    top: 41%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }
`;

export const ThumbnailsWrapper = styled.div`
  width: max-content;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    height: max-content;
    /* position: relative;
    top: 50%;
    transform: translateY(-50%); */

    & > div:nth-child(2) {
      display: grid;
      grid-template-rows: repeat(3, max-content);
      grid-gap: 8px;
    }
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    grid-gap: 12px;
    grid-template-columns: repeat(3, max-content);
    margin: 15px auto;

    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);

    & > div:nth-child(2) {
      height: ${(props) => props.theme.thumbnailSizes.tabletP};
      display: grid;
      grid-template-columns: repeat(3, max-content);
      grid-gap: 12px;
    }
  }
`;

export const ColorSelector = styled.div`
  width: max-content;
  margin: 0 auto;
  margin-bottom: 15px;

  display: grid;

  visibility: ${(props) => (props.show ? 'visibile' : 'hidden')};

  @media ${(props) => props.theme.mediaQueries.mobile} {
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 8px;
    position: absolute;
    left: 45px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 15;
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
  }

  & > div {
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;

    @media ${(props) => props.theme.mediaQueries.mobile} {
      height: 20px;
      width: 20px;
    }

    @media ${(props) => props.theme.mediaQueries.tabletP} {
      height: 20px;
      width: 20px;
    }
  }

  & > div:nth-child(1) {
    background: ${(props) => props.theme.handColors.light};
  }

  & > div:nth-child(2) {
    background: ${(props) => props.theme.handColors.medium};
  }

  & > div:nth-child(3) {
    background: ${(props) => props.theme.handColors.dark};
  }
`;

export const Thumbnail = styled.div`
  border: 1px solid
    ${(props) => (props.selected ? props.theme.colors.gold : 'grey')};

  @media ${(props) => props.theme.mediaQueries.mobile} {
    height: ${(props) => props.theme.thumbnailSizes.mobile};
    width: ${(props) => props.theme.thumbnailSizes.mobile};
    padding: 2px;
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    height: ${(props) => props.theme.thumbnailSizes.tabletP};
    width: ${(props) => props.theme.thumbnailSizes.tabletP};
    padding: 3px;
  }

  & > div {
    background: white;
    cursor: pointer;

    img {
      height: 100%;
      object-fit: cover;
    }

    @media ${(props) => props.theme.mediaQueries.mobile} {
      height: calc(
        ${(props) => props.theme.thumbnailSizes.mobile} - (2 * (2px + 1px))
      );
      width: calc(
        ${(props) => props.theme.thumbnailSizes.mobile} - (2 * (2px + 1px))
      );
    }

    @media ${(props) => props.theme.mediaQueries.tabletP} {
      height: calc(
        ${(props) => props.theme.thumbnailSizes.tabletP} - (2 * (3px + 1px))
      );
      width: calc(
        ${(props) => props.theme.thumbnailSizes.tabletP} - (2 * (3px + 1px))
      );
    }
  }
`;

export const ToggleWrapper = styled.div`
  height: max-content;
  width: max-content;

  position: absolute;
  bottom: ${(props) => props.theme.togglePosition.tabletP.bottom};
  left: ${(props) => props.theme.togglePosition.tabletP.left};
`;

export const ResetWrapper = styled.div`
  height: ${(props) => props.theme.resetButton.mobile.height};
  width: ${(props) => props.theme.resetButton.mobile.width};

  ${(props) => (props.hide ? 'display: none;' : '')}
  margin: 0 auto;

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    display: none;
  }

  & > div {
    height: 100%;
    width: 100%;
    /* width: 50px; */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
`;

export const MobileActions = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ShareWrapper = styled.div`
  text-align: center;
  font-size: 18px;

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    position: absolute;
    bottom: 38px;
    left: 68px;

    height: max-content;
    width: max-content;
    font-size: 22px;

    cursor: pointer;

    &:hover {
      ${(props) =>
        !props.disabled ? `color: ${props.theme.colors.gold}` : ''};
    }

    img {
      height: 22px;
      object-fit: contain;
    }
  }

  ${(props) => (props.disabled ? 'color: #888888;' : '')}
`;

export const HeartWrapper = styled.div`
  position: absolute;
  bottom: 38px;
  right: 68px;

  height: max-content;
  width: max-content;

  cursor: pointer;

  img {
    height: 22px;
    object-fit: contain;
  }

  ${(props) => (props.disabled ? 'color: #888888;' : '')}

  &:hover {
    ${(props) => (!props.disabled ? `color: ${props.theme.colors.gold}` : '')};
  }
`;

export const MobileActionArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
