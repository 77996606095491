import asscher from './Asscher.svg';
import cushion from './Cushion.svg';
import emerald from './Emerald.svg';
import oval from './Oval.svg';
import round from './Round.svg';

const shapes = {
  asscher,
  cushion,
  emerald,
  oval,
  round,
};

export default shapes;
