import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDiamondSize, getConfiguration } from '../../store/threekit';
import { getSection } from '../../store/ui';
import { Wrapper } from './toggle.styles';
import { diamondSizes } from '../../data';

import icons from '../../assets/icons';
const { round: diamondIcon, arrow } = icons;

const Toggle = () => {
  const dispatch = useDispatch();
  const configuration = useSelector(getConfiguration);
  const section = useSelector(getSection);

  const handleClick = () => {
    if (!configuration || !configuration['Diamond Size']) return;

    configuration['Diamond Size'] === diamondSizes['1ct']
      ? dispatch(setDiamondSize(diamondSizes['2ct']))
      : dispatch(setDiamondSize(diamondSizes['1ct']));
  };

  if (section >= 4) return null;

  return (
    <Wrapper selected={configuration?.['Diamond Size']} onClick={handleClick}>
      <div>
        <img src={arrow} alt="toggle-arrow" />
      </div>
      <div>
        <div>
          <img src={diamondIcon} alt="small-size" />
        </div>
        <div>
          <img src={diamondIcon} alt="large-size" />
        </div>
      </div>
    </Wrapper>
  );
};

export default Toggle;
