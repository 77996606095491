import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSection, getDescription } from '../../../store/ui';
import {
  getMetals,
  setMetal,
  getConfiguration,
  setCamera,
} from '../../../store/threekit';
import { SectionWrapper as Wrapper } from '../SharedComponents/sharedStyles.styles';

import { ActionArea, Header } from '../SharedComponents';
import { RadioButtons } from '../../InputComponents';
import { CAMERA_OPTIONS } from '../../../constants';

const Metal = () => {
  const dispatch = useDispatch();
  const metals = useSelector(getMetals);
  const configuration = useSelector(getConfiguration);
  const { Metal: metal } = configuration || { Metal: '' };
  const description = getDescription({
    Metal: configuration.Metal.toLowerCase().replace('_', ' '),
  });

  const handleSelect = val => val !== metal && dispatch(setMetal(val));

  const handleClickNext = () => metal?.length && dispatch(setSection(4));

  const handleClickBack = () => {
    setCamera(CAMERA_OPTIONS.shank);
    dispatch(setSection(2));
  };

  return (
    <Wrapper>
      <Header title="Metal Color" />
      <div>
        <RadioButtons options={metals} onClick={handleSelect} value={metal} />
      </div>
      <ActionArea
        hide={!metal?.length}
        onClick={handleClickNext}
        onClickBack={handleClickBack}
        heading={
          metal?.length ? metals.find(el => el.value === metal)?.label : ''
        }
        buttonText="Summary"
        description={description}
      />
    </Wrapper>
  );
};

export default Metal;
