import styled from 'styled-components';

export const Wrapper = styled.div`
  @media ${(props) => props.theme.mediaQueries.mobile} {
    width: 100%;

    ${(props) => props.count > 3 && 'overflow-x: scroll;'};

    & > div {
      ${(props) =>
        props.count > 3
          ? `
        padding: 5px 135px;
        display: flex;
        flex-direction: row;
        width: max-content;`
          : `
          padding: 8px 0;
          width: max-content;
          display: grid;
          grid-template-columns: repeat(${props.count}, 1fr);`}

      margin: 0 auto;

      & > div {
        ${(props) =>
          props.count > 3 ? 'margin-right: 12px;' : 'margin: 0 auto;'}
      }

      & > div:last-child {
        ${(props) => props.count > 3 && 'margin-right: 0px;'}
      }
    }
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    & > div {
      margin-bottom: 5px;
    }
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    max-height: ${(props) => (props.count > 6 ? '500px' : '360px')};
    height: 100%;

    & > div {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      margin-bottom: 15px;

      & > div {
        flex-basis: ${(props) =>
          [5, 6, 7].includes(props.count)
            ? '33.333333%'
            : [2, 3, 4].includes(props.count)
            ? '50%'
            : 'auto'};
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  cursor: pointer;
  /* color: ${(props) =>
    props.selected ? props.theme.colors.gold : 'black'}; */
  transition: all 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.gold};
  }

  & > div:nth-child(1) {
    margin: 0 auto;
    border-radius: 50%;
    /* border: 1px solid black; */
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  & > div:nth-child(2) {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: GothamLight;
  }

  @media ${(props) => props.theme.mediaQueries.mobile} {
    ${(props) =>
      props.fullwidth ? '' : `width: ${props.theme.radioButtonWidth.mobile};`}

    & > div:nth-child(1) {
      height: ${(props) => props.theme.radioButtonIconSizes.mobile};
      width: ${(props) => props.theme.radioButtonIconSizes.mobile};
      margin-bottom: 5px;
    }

    & > div:nth-child(2) {
      font-size: 13px;
      /* font-size: ${(props) => (props.selected ? '15px' : '10px')}; */
    }
  }

  @media ${(props) => props.theme.mediaQueries.mobileS} {
    & > div:nth-child(1) {
      height: ${(props) => props.theme.radioButtonIconSizes.mobileS};
      width: ${(props) => props.theme.radioButtonIconSizes.mobileS};
    }
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    width: ${(props) => props.theme.radioButtonWidth.mobile};
    font-size: 11px;

    & > div:nth-child(1) {
      height: ${(props) => props.theme.radioButtonIconSizes.tabletP};
      width: ${(props) => props.theme.radioButtonIconSizes.tabletP};
    }

    & > div:nth-child(2) {
      margin-top: 10px;
      height: 14px;
      font-size: ${(props) => (props.selected ? '13px' : '11px')};
      /* line-height: 12px; */
    }
  }
`;
