import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSection,
  getDescription,
  getShankCategory,
  setShankCategory,
} from '../../../store/ui';
import {
  getShanks,
  setShank,
  getConfiguration,
  setCamera,
} from '../../../store/threekit';
import { Header, ActionArea } from '../SharedComponents';
import { RadioButtons } from '../../InputComponents';
import { Wrapper, Tabs, Tab } from './shank.styles';
import { shankCategoryIconMap, descriptions } from '../../../data';
import { camelize } from '../../../utils';
import { icons } from '../../../assets';
import { CAMERA_OPTIONS } from '../../../constants';

const tabs = Object.keys(descriptions.Shank).filter(el => el !== 'master');

const Shank = () => {
  const dispatch = useDispatch();
  const shanks = useSelector(getShanks);
  const shankCategory = useSelector(getShankCategory);
  const configuration = useSelector(getConfiguration);
  const { Shank: shank } = configuration || { Shank: '' };
  const description = getDescription({
    Shank: shankCategory.toLowerCase().replace('_', ' ') || 'master',
  });

  const handleSelectCategory = val =>
    val !== shankCategory && dispatch(setShankCategory(val));

  const handleSelect = val => {
    if (val === shank) return;
    dispatch(setShank(val));
  };

  const handleClickNext = () => {
    if (!shank?.length) return;
    setCamera(CAMERA_OPTIONS.metal);
    dispatch(setSection(3));
  };

  const handleClickBack = () => {
    setCamera(CAMERA_OPTIONS.head);
    dispatch(setSection(1));
  };

  let options = [];

  if (shankCategory && !Array.isArray(shanks) && shanks[shankCategory]) {
    options = shanks[shankCategory];
  }

  return (
    <Wrapper>
      <Header title="Band Style" />
      <div>
        <Tabs>
          {tabs.map((category, i) => (
            <Tab
              key={i}
              selected={shankCategory === category}
              onClick={() =>
                Object.keys(shanks).includes(category.replaceAll(' ', '_')) &&
                handleSelectCategory(category.replaceAll(' ', '_'))
              }
              disable={
                !Object.keys(shanks).includes(category.replaceAll(' ', '_'))
              }
            >
              <div>
                <img
                  src={icons[shankCategoryIconMap[camelize(category)]]}
                  alt={category}
                />
              </div>
              <div>{category}</div>
            </Tab>
          ))}
        </Tabs>
      </div>
      <ActionArea
        onClick={handleClickNext}
        onClickBack={handleClickBack}
        buttonText="Metal"
        heading={
          shankCategory?.toLowerCase().replace('_', ' ') || 'Signature Bands'
        }
        description={description}
      >
        <RadioButtons
          fullwidth
          options={options}
          onClick={handleSelect}
          value={shank}
        />
      </ActionArea>
    </Wrapper>
  );
};

export default Shank;
