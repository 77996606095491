import axisPave from './Axis-Pave.svg';
import axisPolished from './Axis-Polished.svg';
import chancePave from './Chance-Pave.svg';
import chancePolished from './Chance-Polished.svg';
import regular8LinkPave from './Regular-8-Link-Pave.svg';
import regular8LinkPolished from './Regular-8-Link-Polished.svg';
import regularPolished from './Regular-Polished.svg';
import singleRowPave from './Single-Row-Pave.svg';
import slim16LinkPave from './Slim-16-Link-Pave.svg';
import slim16LinkPolished from './Slim-16-Link-Polished.svg';
import slimPolished from './Slim-Polished.svg';
import threeRowPave from './Three-Row-Pave.svg';

const shanks = {
  //  - Chance
  chancePave,
  chancePolished,
  //  - Axis
  axisPave,
  axisPolished,
  //  - Three Phases
  threePhasesSlim: slimPolished,
  threePhasesRegular: regularPolished,
  threePhasesSingleRow: singleRowPave,
  threePhasesTripleRow: threeRowPave,
  //  - Links
  linksRegular16Pave: slim16LinkPave,
  linksMini16Polished: slim16LinkPolished,
  linksRegular8Pave: regular8LinkPave,
  linksRegular8Polished: regular8LinkPolished,
};

export default shanks;
