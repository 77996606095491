import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 600px;
  display: grid;
  grid-template-rows: max-content auto max-content;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    height: 100%;
  }
`;

export const WrapperAlt = styled(Wrapper)`
  grid-template-rows: max-content max-content auto max-content;
`;

export const Description = styled.div`
  width: 82%;
  color: black;
  margin: 0 auto;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    overflow-y: scroll;
  }

  p {
    font-family: 'EB Garamond', serif;
    letter-spacing: 1px;
  }
`;

export const TableWrapper = styled.div`
  height: 100%;
`;

export const Button = styled.div`
  width: max-content;
  float: right;
  margin-bottom: 20px;
  font-family: BruneiDarussalam;
  font-size: 45px;
  cursor: pointer;
  color: black;
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.gold};
  }

  @media ${(props) => props.theme.mediaQueries.mobile} {
    margin-bottom: 15px;
    font-size: 20px;
    font-family: GothamLight;
  }

  @media ${(props) => props.theme.mediaQueries.tabletP} {
    height: 40px;
  }
`;

export const ActionArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 25px 0px 25px;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    margin-top: 3px;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
  text-align: center;
`;
