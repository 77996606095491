import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERIES } from './theme';

import { DesktopApp, MobileApp } from './apps';

const App = () => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.mobile });
  return isMobile ? <MobileApp /> : <DesktopApp />;
};

export default App;
