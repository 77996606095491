import { CACHE_CONFIGURATION_REQUIREMENTS } from './constants';
const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const camelize = (str) =>
  str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });

export const getParams = () => {
  let query = window.location.search.substr(1);
  return query.split('&').reduce((output, part) => {
    let [key, value] = part.split('=');
    const preppedValue = decodeURIComponent(value);
    output[decodeURIComponent(key)] = IsJsonString(preppedValue)
      ? JSON.parse(preppedValue)
      : preppedValue;
    return output;
  }, {});
};

export const copyToClipboard = (data) => {
  if (!data) return;
  const str = typeof data === 'string' ? data : JSON.stringify(data);
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const filterConfigurationForCache = (configuration) =>
  Object.entries(configuration).reduce((output, [attrName, attrData]) => {
    if (output === undefined) return output;
    if (!CACHE_CONFIGURATION_REQUIREMENTS.includes(attrName)) return output;
    if (!attrData.length) return undefined;
    return Object.assign(output, { [attrName]: attrData });
  }, {});

export const cacheConfiguration = (configuration) => {
  const preppedConfiguration = filterConfigurationForCache(configuration);
  if (!preppedConfiguration) {
    console.log('Clearing out localstorage');
    localStorage.removeItem('configurtion');
  } else {
    console.log('Caching configuration in localstorage');
    localStorage.setItem('configuration', JSON.stringify(configuration));
  }
};
