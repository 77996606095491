import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto max-content;

  @media ${props => props.theme.mediaQueries.tabletP} {
    height: 400px;
  }

  & > div:nth-child(1) {
    overflow: hidden;
  }

  & > div:nth-child(2) {
  }
`;

export const Scrollable = styled.div`
  overflow-y: scroll;
`;

const RowTemplate = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 56px;
  grid-gap: 10px;
  color: black;
  padding: 0 15px;
  height: 40px;

  font-size: 14px;
  font-family: GothamLight;
  text-align: center;

  & > div {
    height: max-content;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  img {
    height: 20px;
  }
`;

export const RowHeading = styled(RowTemplate)`
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  padding: 0 15px;
  height: 38px;

  & > div {
    height: max-content;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const RowWrapper = styled(RowTemplate)`
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  cursor: pointer;

  & > div:nth-child(5) {
    opacity: ${props => (props.selected ? 1 : 0)};
  }

  ${props =>
    props.selected && `background: ${props.theme.colors.gold}aa; color: white;`}

  @media ${props => props.theme.mediaQueries.tabletP} {
    &:hover {
      ${props => !props.selected && `background: rgba(255, 255, 255, 0.4);`}

      & > div:nth-child(5) {
        opacity: 1;
      }
    }
  }
`;
