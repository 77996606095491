import React from 'react';
import {
  Wrapper,
  HeadingWrapper,
  TitleWrapper,
  Description,
  Buttons,
  Button,
} from './actionArea.styles';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

const Heading = ({ show, heading, description, center }) => {
  return (
    <HeadingWrapper center={center}>
      <TitleWrapper show={show}>
        <div>
          <hr />
        </div>
        <div>{heading}</div>
        <div>
          <hr />
        </div>
      </TitleWrapper>
      <Description>{description}</Description>
    </HeadingWrapper>
  );
};

const ActionArea = props => {
  const { heading, description, onClickBack, onClick, hide, children } = props;
  return (
    <Wrapper hide={!!hide} hasChildren={!!children}>
      <div>
        <div>
          <Heading
            center={!children}
            show={heading?.length}
            heading={heading}
            description={description}
          />
        </div>
        {children}
        <Buttons>
          <Button hide={!!!onClickBack} onClick={onClickBack}>
            <div>
              <ArrowLeftOutlined
                style={{ fontSize: '19px', marginRight: '3px' }}
              />
              back
            </div>
          </Button>
          <Button onClick={onClick}>
            <div>
              next
              <ArrowRightOutlined
                style={{ fontSize: '19px', marginLeft: '3px' }}
              />
            </div>
          </Button>
        </Buttons>
      </div>
    </Wrapper>
  );
};

export default ActionArea;
