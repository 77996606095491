import React from 'react';
import { Wrapper, ButtonWrapper } from './radioButtons.styles';
import labelsMap from '../../../data/labels.json';
import { icons } from '../../../assets';

const RadioButtons = ({ options, onClick, value: selected, fullwidth }) => {
  if (!options) return null;
  return (
    <Wrapper count={options.length}>
      <div>
        {options
          .filter(el => el && el.label)
          .map(({ label, value, icon }, i) => (
            <ButtonWrapper
              key={i}
              fullwidth={fullwidth}
              selected={value === selected}
              onClick={() => onClick(value, icon)}
            >
              <div>
                <img src={icons[icon]} alt={value} />
              </div>
              {/* <div>{icon}</div> */}
              <div>{labelsMap[label] || label}</div>
            </ButtonWrapper>
          ))}
      </div>
    </Wrapper>
  );
};

export default RadioButtons;
