import miscIcons from './misc';
import headIcons from './heads';
import shapeIcons from './shapes';
import shankIcons from './shanks';
import metalIcons from './metals';

const icons = {
  //  General Icons
  ...miscIcons,
  //  Lindsey's Icons
  ...headIcons,
  ...shapeIcons,
  ...shankIcons,
  ...metalIcons,
};

export default icons;
